import { render, staticRenderFns } from "./BusinessSummary.vue?vue&type=template&id=f52fe71e&scoped=true&lang=pug"
import script from "./BusinessSummary.coffee?vue&type=script&lang=coffee&external"
export * from "./BusinessSummary.coffee?vue&type=script&lang=coffee&external"
import style0 from "./BusinessSummary.sass?vue&type=style&index=0&id=f52fe71e&prod&lang=sass&scoped=true&external"
import style1 from "./BusinessSummary.vue?vue&type=style&index=1&id=f52fe71e&prod&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f52fe71e",
  null
  
)

export default component.exports