import moment from "moment";
import axios from "axios";
import Loader from "@/components/Loader/Loader.vue";
import lineChart from "@/components/charts/lineChart.vue"

export default {
    name: "FlipkartDisplayCampaign",
    components: { Loader, lineChart },
    data() {
        return {
            loader: false,
            dateRange: {
                startDate: moment().subtract(1, 'month').format("YYYY-MM-DD"),
                endDate: moment().format("YYYY-MM-DD")
            },
            dateRangeCompare: {
                startDate: moment().subtract(2, 'months').format("YYYY-MM-DD"),
                endDate: moment().subtract(1, 'month').format("YYYY-MM-DD")
            },
            compareDate: false,
            bulkData: [],
            campaignsArr: [],
            selectedCampaigns: "",
            timelineArr: [
                { label: 'Day', value: "day" },
                { label: 'Month', value: "month" }
            ],
            selectedTimeline: "",
            timelineTableHeader: [],
            timelineTableData: [],
            timelineMetricsArr: [],
            selectedMetrics: "",
            timelineChart: {
                xAxis: [],
                seriesData: {},
                ylabel: "",
                legend: false
            },
            componentRender: 0,
        }
    },
    mounted() {
        this.getCampaignList();
    },
    watch: {
        dateRange: {
            deep: true,
            handler() {
                this.getCampaignList();
                $("#fkTimelineTable").DataTable().destroy();
                this.timelineTableHeader = [];
                this.timelineTableData = [];
                this.getTimelineData();
            }
        },
        dateRangeCompare: {
            deep: true,
            handler() {
                this.getCampaignList();
                $("#fkTimelineTable").DataTable().destroy();
                this.timelineTableHeader = [];
                this.timelineTableData = [];
                this.getTimelineData();
            }
        },
        selectedCampaigns: {
            deep: true,
            handler(oldVal, newVal){
                if(newVal !== oldVal){
                    this.getBulkData();
                    $("#fkTimelineTable").DataTable().destroy();
                    this.timelineTableHeader = [];
                    this.timelineTableData = [];
                    this.getTimelineData();
                }
            }
        },
        selectedTimeline: {
            deep: true,
            handler(oldVal, newVal) {
                if (newVal !== oldVal) {
                    $("#fkTimelineTable").DataTable().destroy();
                    this.timelineTableHeader = [];
                    this.timelineTableData = [];
                    this.getTimelineData();
                }
            }
        },
        selectedMetrics: {
            deep: true,
            handler(oldVal, newVal) {
                if (newVal !== oldVal) {
                    this.processTimelineChart();
                    this.componentRender += 1;
                }
            }
        }
    },
    methods: {
        getCampaignList(){
            const url = "/api/flipkart/display/camplist";
            const payload = {
                start_date: moment(this.dateRange.startDate).format("YYYY-MM-DD"),
                end_date: moment(this.dateRange.endDate).format("YYYY-MM-DD")
            };
            this.campaignsArr = []
            this.loader = true;
            axios.get(url, {params: payload}).then(res => {
                this.campaignsArr = res.data.data
                this.loader = false;
            }).catch(err => {
                console.log(err);
                this.loader = false;
            })
        },
        getBulkData() {
            this.loader = true;
            const url = "/api/flipkart/display/campbulk";
            let campPayload = [];
            let payload = {
                first_start_date: moment(this.dateRangeCompare.startDate).format("YYYY-MM-DD"),
                first_end_date: moment(this.dateRangeCompare.endDate).format("YYYY-MM-DD"),
                last_start_date: moment(this.dateRange.startDate).format("YYYY-MM-DD"),
                last_end_date: moment(this.dateRange.endDate).format("YYYY-MM-DD")
            };
            if(this.selectedCampaigns.length){
                for(let campaigns of this.selectedCampaigns){
                    campPayload.push(campaigns.campaign_id)
                }
                console.log()
                payload = {...payload, 
                    campaign_id: campPayload.toString() 
                }
            } else return;
            
            axios.get(url, { params: payload }).then(res => {
                this.bulkData = res.data.data
                this.loader = false
            }).catch(err => {
                console.log(err);
                this.loader = false;
            });
        },
        getTimelineData() {
            this.loader = true;
            const url = "/api/flipkart/display/camp_histogram";
            let campPayload = [];
            let payload = {
                start_date: moment(this.dateRange.startDate).format("YYYY-MM-DD"),
                end_date: moment(this.dateRange.endDate).format("YYYY-MM-DD"),
                timeline: this.selectedTimeline.value
            };
            if(this.selectedCampaigns.length){
                for(let campaigns of this.selectedCampaigns){
                    campPayload.push(campaigns.campaign_id)
                }
                console.log()
                payload = {...payload, 
                    campaign_id: campPayload.toString() 
                }
            } else return;
            axios.get(url, { params: payload }).then(res => {
                this.timelineTableHeader = res.data.data.header;
                this.timelineTableData = res.data.data.data;
                this.timelineTableHeader.map(dataset => {
                    if (dataset.key !== 'date') {
                        this.timelineMetricsArr.push({ value: dataset.key, label: dataset.label })
                    };
                });
                this.$nextTick(() => {
                    this.processTimelineTable();
                    this.processTimelineChart()
                });
                this.loader = false;
            }).catch(err => {
                console.log(err);
                this.loader = false;
            });
        },
        processTimelineTable() {
            let tableID = `#fkTimelineTable`;
            if (this.timelineTableData.length > 10) {
                var search = require("@/assets/Icons_SVG/Search.svg");
                $(tableID).dataTable({
                    searching: true,
                    info: false,
                    paginate: true,
                    lengthChange: true,
                    sorting: [[0, "desc"]],
                    initComplete: function(settings, json) {
                        $('.dataTables_scrollBody tfoot tr').css({visibility:'collapse'});
                        $('.dataTables_scrollBody thead tr').css({visibility:'collapse'});
                        $(tableID).wrap("<div style='overflow:auto; width:100%;position:relative;'></div>");
                    },
                    language: {
                        search: "", searchPlaceholder: "Search...", paginate: {
                            first: "",
                            previous: "<",
                            next: ">",
                            last: "",
                        },
                        lengthMenu: "<h5>Show entries</h5> _MENU_"
                    },
                })
                $(tableID + "_filter > label").append(
                    `<div class='search_icon'><img src='${search}' /></div>`
                );
            } else {
                $(tableID).dataTable({
                    searching: false,
                    info: false,
                    lengthChange: false,
                    sorting: [[0, "desc"]],
                    initComplete: function(settings, json) {
                        $('.dataTables_scrollBody tfoot tr').css({visibility:'collapse'});
                        $('.dataTables_scrollBody thead tr').css({visibility:'collapse'});
                        $(tableID).wrap("<div style='overflow:auto; width:100%;position:relative;'></div>");
                    },
                    paginate: false,
                })
            }
        },
        processTimelineChart() {
            let plotXaxisData = [];
            let plotSeriesData = [
                { name: "", data: [] },
                { name: "", data: [] },
            ];
            this.timelineTableData.map(dataset => {
                plotXaxisData.push(moment(dataset.date).format("DD-MMM"));
                if (typeof (dataset[this.selectedMetrics.value]) === 'object') {
                    plotSeriesData[0].name = "Direct";
                    plotSeriesData[1].name = "Indirect";
                    this.timelineChart.ylabel = this.selectedMetrics.label;
                    this.timelineChart.legend = true;
                    if(this.selectedMetrics.value === 'revenue'){
                        plotSeriesData[0].data.push(dataset[this.selectedMetrics.value].direct_revenue);
                        plotSeriesData[1].data.push(dataset[this.selectedMetrics.value].indirect_revenue);
                    } else if(this.selectedMetrics.value === 'units_sold') {
                        plotSeriesData[0].data.push(dataset[this.selectedMetrics.value].directly_converted_units);
                        plotSeriesData[1].data.push(dataset[this.selectedMetrics.value].indirectly_converted_units);
                    }else {
                        plotSeriesData[0].data.push(dataset[this.selectedMetrics.value].direct);
                        plotSeriesData[1].data.push(dataset[this.selectedMetrics.value].indirect);
                    }
                } else {
                    delete plotSeriesData[1]
                    delete this.timelineChart.ylabel
                    this.timelineChart.legend = false;
                    plotSeriesData[0].name = this.selectedMetrics.label
                    plotSeriesData[0].data.push(dataset[this.selectedMetrics.value])
                }
            })
            this.timelineChart.seriesData = plotSeriesData;
            this.timelineChart.xAxis = plotXaxisData;
        },
        toggleCompare() {
            this.compareDate = !this.compareDate
        },
        formatNumber(num) {
            if (isNaN(num)) {
                return num
            }
            const num1 = Number(num).toFixed(2);
            const num2 = Number(num1).toLocaleString();
            return num2;
        }
    },
}