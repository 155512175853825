import axios from 'axios'
import dualAxisChart from '@/components/charts/dualAxisChart.vue'
import stackChart from '@/components/charts/stackChart.vue'
import lineChart from '@/components/charts/lineChart.vue'

import moment from 'moment'
import Loader from '@/components/Loader/Loader.vue'
export default
	name:'BusinessSummary'
	components:{
		Loader,
		dualAxisChart,
		stackChart,
		lineChart
	},
	data:()->
		isLoader:false
		metricesLoader:false
		customRanges: null
		isGA4: JSON.parse(localStorage.userData).menu.GA4
		dateRange: 
			startDate: moment().subtract(1,'months').format('YYYY-MM-DD')
			endDate: moment().format('YYYY-MM-DD')
		activeCompare: false
		GSDemo:{}
		generalSummary:
			metricSelect: 'month'
			thirdMetricSelectDropdown: [
				{value: '', label: 'Overall Spends'},
				{value: 'adword', label: 'Adword Spends'},
				{value: 'fb', label: 'Facebook Spends'}
			]
			selectedThirdMetricSelectDropdown: {value: '', label: 'Overall Spends'},
			thirdMetricSelectIdx: ''
			viewStatsData:{
				month:[],
				mtd:[],
				qtd:[],
				ytd:[]
			},
			viewSpendsStatsData:{
				month:[],
				mtd:[],
				qtd:[],
				ytd:[]
			}
		overallSummary:
			metricDropdown: {value: 'day', label: 'Day'}
			ecomGraph:
				data:[]
				transactionsVsRevenueChart:
					xAxis:[]
					seriesData:[]
				transactionsVsASPChart:
					xAxis:[]
					seriesData:[]
			revenueEffGraph:
				metricDropdown: {value: 'all', label: 'Overall'}
				data:[]
				revenueVsRevenueEffChart:
					xAxis:[]
					seriesData:[]
				spendsVsRevenueEffChart:
					xAxis:[]
					seriesData:[]
		topBoxesDataSourceIcon:
			"Google Ads": 'Ads'
			"GA4" : 'Analytics'
			"Meta" : 'meta',
			"Shopify": "Shopify"
	

		viewTopBoxesData: []
		viewOldAPITopBoxesData:[]
		viewTopGraphData:[]
		viewTopCompGraphData:[]
		topGraphDataArray:[],
		processedTopBoxesData:[{
			sectionName:'',
			isSectionOpen: true,
			boxData: [{
				key:'',
				oldDateRange: '',
				oldValue: '',
				percentDifference:''
				prifix: '',
				suffix: '',
				value: '',
				dataSource:'',
				graphData:[]
			}]}
		]
		graphView: false # Metric View
		firstSection : false
		current : JSON.parse(localStorage.userData).clients.current
		chartData: {}
		channelStackView:
			durationDropdown: {value: 'month', label: 'Month'}
			metricDropdown: {value: '0', label: 'Transactions'}
			data: []
			chart: 
				xAxis: []
				seriesData: [] 
		cityTableData:
			header: [
				{ key: "city",label: "Location" },
				{ key: "Transactions",label: "Transactions" },
				{ key: "Revenue",label: "Revenue(Rs.)" },
				{ key: "Avg Selling Price",label: "Avg Selling Price(Rs.)" },
				{ key: "Avg. Order Value",label: "Avg. Order Value(Rs.)" }
			]
			data: []
			metricDropdown: {value: 'Avg Selling Price', label: 'Transactions vs ASP'}
			currentTableState: {}
			chart: 
				xAxis:[],
				seriesData:[] 

		productsTableData:
			header: [
				{ key: "productName",label: "Product Name" },
				{ key: "Unuique Purchases",label: "Unique Purchased" },
				{ key: "Product Revenue",label: "Product Revenue(Rs.)" }
				{ key: "Avg Selling Price",label: "ASP(Rs.)" }
				{ key: "quantity",label: "Quantity" }
			]
			data: []
			metricDropdown: {value: 'Avg Selling Price', label: 'Transactions vs ASP'}
			currentProductsTableState: {}
			chart: 
				xAxis:[],
				seriesData:[] 

		channelTableData:
			header: [
				{ key: "channel",label: "Channel" }
				{ key: "Transactions",label: "Transactions" }
				{ key: "Revenue",label: "Revenue(Rs.)" }
				{ key: "Avg Selling Price",label: "Avg Selling Price(Rs.)" }
				{ key: "Avg. Order Value",label: "Avg. Order Value(Rs.)" }
			]
			data: []
			metricDropdown: {value: 'Revenue', label: 'Transactions VS Revenue'}
			currentChannelTableState: {}
			chart: 
				xAxis:[]
				seriesData:[]
		tabList:[
			{"key":"Key Metrics Summary", "value": "Key Metrics"},
			{ "key": "Revenue", "value": "Revenue" },
			{ "key": "Transactions", "value": "Transactions" },
			{ "key": "Sessions", "value": "Sessions" },
			{ "key": "ATC", "value": "ATC" },
			{ "key": "Spends", "value": "Spends" },
			{ "key": "ROAS", "value": "ROAS" },
			{ "key": "AOV", "value": "AOV" },
			{ "key": "Conversion Rate", "value": "Conversion Rate" },
			{ "key": "Shopify", "value": "Shopify" }
		],
		selectedTab:'Key Metrics Summary',
		channelTrendDropdown:[
			{ label: "All", value: 'All' },
			{ label: "Google Analytics", value: 'GA4' },
			{ label: "Google Adword", value: 'Google Ads' },
			{ label: "Meta", value: 'Meta' },
			{ label: "Shopify", value: 'Shopify' }
		],
		selectedChannel: { label: "All", value: 'All' }
		# getReportData: 
		# 	coverTitle: 'Business Summary'
		# 	start_date: ''
		# 	end_date: ''
		# 	business: {}
		# 	filter: {}
		# 	stats: {}
		# 	daterange: {}
		# 	spendstats: {}
		# 	stackData: {}
		# 	spendvsrevenue: {}
		# 	nonecomTableData: {}
		# 	newBoxes: {} 
	created:()->
		today = new Date()
		yesterday = new Date(today.getFullYear(), today.getMonth(), today.getDate() - 1)
		monthStartDate= new Date(today.getFullYear(), today.getMonth() - 1, today.getDate() + 0);
		thisMonthStartDate = new Date(today.getFullYear(), today.getMonth(), 1)
		thisMonthEndDate = new Date()
		lastMonthStartDate = new Date(today.getFullYear(), today.getMonth() - 1, 1)
		lastMonthEndDate = new Date(today.getFullYear(), today.getMonth(), 0)
		thisYearStartDate = new Date(today.getFullYear(), 0, 1)
		thisYearEndDate = new Date(today.getFullYear(), 11, 31)
		lastYearStartDate = new Date(today.getFullYear() - 1, 0, 1)
		lastYearEndDate = new Date(today.getFullYear() - 1, 11, 31)
		quarterStartMonth = Math.floor((today.getMonth() / 3)) * 3; 
		quarterStartDate = new Date(today.getFullYear(), quarterStartMonth, 1);

		this.customRanges =
			'Today': [today, today],
			'Yesterday': [yesterday, yesterday],
			'Month': [monthStartDate, thisMonthEndDate]
			'Last month': [lastMonthStartDate, lastMonthEndDate]
			'Last Year': [lastYearStartDate, lastYearEndDate]
			'MTD': [thisMonthStartDate, today]
			'QTD': [quarterStartDate, today]
			'YTD': [thisYearStartDate, today]
			
	mounted:()->
		this.init()
		# this.getDateRange()

	methods:
		init:()->
			this.dataAPIs()
		# getDateRange:()->
		# 	self = this
		# 	axios.get('/api/summary/daterange/').then((res) -> 
		# 		self.getReportData.daterange = res.data
		# 	).catch((err) -> console.log err)
		formatNumber:(num) ->
			if isNaN(num)
				return num
			num1 = Number(num).toFixed(2)
			num2 = Number(num1).toLocaleString()
			return num2

		abbreviateNumber:(value) ->
			Intl.NumberFormat('en-US', {
				notation: "compact",
				maximumFractionDigits: 2
			}).format(value);

		dateChange:()->
			$('#topcity-tbl').DataTable().destroy()
			$('#topproducts-tbl').DataTable().destroy()
			$('#topchannel-tbl').DataTable().destroy()
			this.dataAPIs()
		
		decodeHTML:(html)->
			txt = document.createElement('textarea')
			txt.innerHTML = html;
			return txt.value  
			# // decoder for HTML-encoded strings.

		durationChange:()->
			this.overallSummaryAPIs()
		
		changeGSDuration:(durationType)->
			this.generalSummary.metricSelect = durationType

		changeView:(viewType)->
			this.selectedViewOfTop = viewType

		revenueEffCategoryChange:()->
			this.getRevenueEffGraph()

		dataAPIs:()-> 

			if(this.isGA4==true)
				this.getIntervalData();
				# this.getTopBoxesDataNewAPI()
			else
				this.getTopBoxesDataOldAPI()
				this.getCityChannelProductTablesData()
				this.overallSummaryAPIs()
				this.getChannelStackViewData()
				this.getGeneralSummary()
			# this.getGSSpendsStatsData()
		
		getIntervalData:()->
			self = this
			self.isLoader = true
			urlStats="/api/v1/businesssummary/interval"
			axios.get(urlStats).then((res) ->
				self.isLoader = false
				if(res.data.data)
					# self.dateRange.startDate = moment().subtract(res.data.data.interval,'days').format('YYYY-MM-DD')
					self.getTopBoxesDataNewAPI()
			).catch (err) ->
				console.log(err)
				self.isLoader = false

		getGeneralSummary: ()->
			this.getGSStatsData()
			this.getGSSpendsStatsData()
		
		# downloadPDF:()->
		# 	self = this
		# 	url= '/api/report/business_summary'
		# 	self.getReportData.start_date = self.dateRange.startDate
		# 	self.getReportData.end_date = self.dateRange.endDate
		# 	self.getReportData.spendTitle = self.generalSummary.selectedThirdMetricSelectDropdown.label 
		# 	console.log(self.getReportData)

		getGSStatsData:()->
			self = this
			self.isLoader = true
			urlStats="/api/ga/ecommerce/v2/stats"
			axios.get(urlStats).then((res) ->
				if(res.data.data)
					self.generalSummary.viewStatsData= res.data.data
					self.isLoader = false
			).catch (err) ->
				console.log(err)
				self.isLoader = false

		getGSSpendsStatsData:()->
			self=this
			self.isLoader = true
			url="/api/adword/v2/spendstats?cost_type="+this.generalSummary.selectedThirdMetricSelectDropdown.value
			axios.get(url).then((res) ->
				if(res.data.data)
					self.generalSummary.viewSpendsStatsData=res.data.data
					self.isLoader = false
			#    self.viewTopBoxesData=res.data.data
			).catch (err) ->
				console.log(err)
				self.isLoader = false

		getChannelStackViewData:()->
			self = this
			self.isLoader = true
			url="/api/summary/business/?channel=#{self.channelStackView.durationDropdown.value}"
			if self.channelStackView.durationDropdown.value == 'day'
				url += "&start_date=#{moment(self.dateRange.startDate).format('YYYY-MM-DD')}"
				url += "&end_date=#{moment(self.dateRange.endDate).format('YYYY-MM-DD')}"
			axios.get(url).then((res) ->
				self.channelStackView.data = res.data.data
				self.processChannelStackViewGraph()
				self.isLoader= false
			).catch (err) ->
				console.log(err)
				self.isLoader = false

		processChannelStackViewGraph:(res) ->
			self = this
			self.channelStackView.chart = 
				xAxis: []
				seriesData: []
			xaxis = [] 
			seriesData = []
			colorMapper = 
				'Branded Paid Search' : "#ebefbf"
				'(Other)' : "#ddc7f8"
				'Generic Paid Search' : "#ff8392"
				'Direct' : "#ffa2a4"
				'Display' :  "#ffd0b1"
				'Social' : "#d7edbb"
				'Referral' :  "#9ddec5"
				'Paid Search' : "#899efc"
				'Organic Search' : "#eaa9fb"
				'Affiliates' : "#ffd7d8"
				'Email' : "#dbd0bf" 
				'Other Adertising': '#88aec1',
				'SMS': '#dbd0bf'

			if(self.channelStackView.data.length) 
				for d in Object.keys(self.channelStackView.data[0])
					if d != 'date'
						seriesData.push({name: d,data: [],color: colorMapper[d] })

			for d in self.channelStackView.data
				for s in seriesData
					s.data.push(d[s.name][self.channelStackView.metricDropdown.value])
				xaxis.push(d.date)

			self.channelStackView.chart = 
				xAxis: xaxis
				seriesData: seriesData 

		overallSummaryAPIs:()->
			this.getEcomData()
			this.getRevenueEffGraph()

		getTopBoxesDataOldAPI:()->
			self=this
			self.viewOldAPITopBoxesData = []
			url="/api/ga/ecommerce/orders?"
			url += "start_date=#{moment(self.dateRange.startDate).format('YYYY-MM-DD')}"
			url += "&end_date=#{moment(self.dateRange.endDate).format('YYYY-MM-DD')}"
			axios.get(url).then((res) ->
				self.viewOldAPITopBoxesData=res.data.data
			).catch (err) ->
				console.log(err)
				self.metricesLoader = false
	
		getTopBoxesDataNewAPI:()->
			self=this
			self.metricesLoader = true
			self.firstSection = false
			self.isLoader = true
			self.topGraphDataArray = []
			self.viewTopBoxesData = []
			self.viewTopGraphData = []
			self.viewTopCompGraphData=[]
			url="/api/v3/summary/business/?"
			url += "start_date=#{moment(self.dateRange.startDate).format('YYYY-MM-DD')}"
			url += "&end_date=#{moment(self.dateRange.endDate).format('YYYY-MM-DD')}"
			url += "&metric_type=#{this.selectedTab   }"
			axios.get(url).then((res) ->
				self.viewTopBoxesData=res.data.data
				self.processTopBoxesData(res.data.data)
				self.metricesLoader = false
				self.firstSection = true
				self.isLoader = false
			).catch (err) ->
				console.log(err)
				self.metricesLoader = false

		getEcomData:()->
			self = this
			self.isLoader = true
			url = "/api/ga/ecommerce?"
			url += "filter=#{self.overallSummary.metricDropdown.value}"
			url += "&start_date=#{moment(self.dateRange.startDate).format('YYYY-MM-DD')}"
			url += "&end_date=#{moment(self.dateRange.endDate).format('YYYY-MM-DD')}"
			axios.get(url).then((res) ->
				self.overallSummary.ecomGraph.data=res.data.data
				self.processEcomGraph()
			).catch (err) ->
				console.log(err)
				self.isLoader = false

		getRevenueEffGraph:()->
			self = this
			self.isLoader = true
			url = "/api/summary/spendsvsrevenue/?"
			url += "channel=#{self.overallSummary.revenueEffGraph.metricDropdown.value}&"
			url += "start_date=#{moment(self.dateRange.startDate).format('YYYY-MM-DD')}&"
			url += "end_date=#{moment(self.dateRange.endDate).format('YYYY-MM-DD')}"
			axios.get(url).then((res) ->
				self.overallSummary.revenueEffGraph.data=res.data.data
				self.processRevenueEffGraph()
			).catch (err) ->
				console.log(err)
				self.isLoader = false

		getCityChannelProductTablesData:() ->
			self = this
			self.isLoader = true
			url = "/api/summary/business/?"
			url += "start_date=#{moment(self.dateRange.startDate).format('YYYY-MM-DD')}"
			url += "&end_date=#{moment(self.dateRange.endDate).format('YYYY-MM-DD')}"
			axios.get(url).then((res)-> 
				self.cityTableData.data = res.data.city
				self.productsTableData.data = res.data.products
				self.channelTableData.data = res.data.channel
				self.$nextTick -> 
					self.processCityTable()
					self.processProductsTable()
					self.processChannelTable()
				self.isLoader = false
			).catch((err)->
				console.log(err)
				self.isLoader = false
			)

		processCityTable:()->
			self = this
			cityTable = $('#topcity-tbl').DataTable({ info:false, searching: false ,sorting: [[ 1, "desc" ]],language: { search: "", searchPlaceholder: "Search"},   
			initComplete:()-> 
				$('#topcity-tbl').wrap("<div style='overflow:auto; width:100%;position:relative;'></div>")
			})
			cityTable.on 'draw.dt', ->
				$('#topcity-tbl_paginate').remove()
				$('#topcity-tbl_length').remove()
				$('.dataTables_scrollBody thead tr').css({visibility:'collapse'});
				citiesData = []
				transactionsData = []
				revenueData = []
				aspData = []
				$.each cityTable.rows(filter: 'applied').data(), (index, value) ->
					if index<10 
						citiesData.push(value[0])
						transactionsData.push(Number(value[1].replace(/,/g,'')))
						revenueData.push(Number(value[2].replace(/,/g,''))) 
						aspData.push(Number(value[3].replace(/,/g,'')))
				self.cityTableData.currentTableState = {
					citiesData: citiesData
					transactionsData: transactionsData
					revenueData: revenueData
					aspData: aspData
				}
				self.processCityGraph() 
			$('#topcity-tbl').DataTable().draw() 
			self.isLoader= false		

		processProductsTable:()->
			self = this
			productsTable = $('#topproducts-tbl').DataTable({ info: false, searching: false, sorting: [[ 1, "desc" ]], language: { search: "", searchPlaceholder: "Search"} 
			initComplete:()-> 
				$('#topproducts-tbl').wrap("<div style='overflow:auto; width:100%;position:relative;'></div>")
			})
			productsTable.on 'draw.dt', ->
				$('#topproducts-tbl_paginate').remove()
				$('#topproducts-tbl_length').remove()
				$('.dataTables_scrollBody thead tr').css({visibility:'collapse'});
				productsData = []
				quantityData = []
				productsRevenueData = []
				productsASPData = []
				$.each productsTable.rows(filter: 'applied').data(), (index, value) ->
					if index<10
						productsData.push(value[0])
						quantityData.push(Number(value[1].replace(/,/g,'')))
						productsRevenueData.push(Number(value[2].replace(/,/g,'')))
						productsASPData.push(Number(value[3].replace(/,/g,'')))
				self.productsTableData.currentProductsTableState = {
					productsData: productsData
					quantityData: quantityData
					productsRevenueData: productsRevenueData
					productsASPData: productsASPData
				}
				self.processProductsGraph()
			$('#topproducts-tbl').DataTable().draw()
			self.isLoader = false

		processChannelTable:()->
			self = this
			channelTable = $('#topchannel-tbl').DataTable({ info: false, searching: false, sorting: [[ 1, "desc" ]], language: { search: "", searchPlaceholder: "Search"} 
			initComplete:()-> 
				$('#topchannel-tbl').wrap("<div style='overflow:auto; width:100%;position:relative;'></div>")
			})
			channelTable.on 'draw.dt', ->
				$('#topchannel-tbl_paginate').remove()
				$('#topchannel-tbl_length').remove()
				$('.dataTables_scrollBody thead tr').css({visibility:'collapse'});
				channelData = []
				channelTransactionsData = []
				channelRevenueData = []
				channelASPData = []
				$.each channelTable.rows(filter: 'applied').data(), (index, value) ->
					if index<10
						channelData.push(value[0])
						channelTransactionsData.push(Number(value[1].replace(/,/g,'')))
						channelRevenueData.push(Number(value[2].replace(/,/g,'')))
						channelASPData.push(Number(value[3].replace(/,/g,'')))
				self.channelTableData.currentChannelTableState = {
					channelData: channelData
					channelTransactionsData: channelTransactionsData
					channelRevenueData: channelRevenueData
					channelASPData: channelASPData 
				}
				self.processChannelGraph()
			$('#topchannel-tbl').DataTable().draw() 
			self.isLoader=false


		processRevenueEffGraph:()->
			self = this
			self.overallSummary.revenueEffGraph.revenueVsRevenueEffChart = 
				xAxis: []
				seriesData: []

			self.overallSummary.revenueEffGraph.spendsVsRevenueEffChart = 
				xAxis: []
				seriesData: []

			xaxis = []
			revenueEff=''
			seriesDataRevenue = [
				{
					name: 'Revenue (Rs)'
					data: []
				}
				{
					name: 'Revenue Efficiency',
					data: []
					color: "#FCB116"
				}
			]
			seriesDataSpends = [
				{
					name: 'Spends (Rs)'
					data: []
				}
				{
					name: 'Revenue Efficiency',
					data: []
					color: "#FCB116"
				}
			]
			inputFormat = { 'day': 'YYYY-MM-DD','month': 'YYYY|MM'}
			outputFormat = { 'day': 'DD MMM YYYY','month': 'MMM YYYY'}
			dropdownVal = self.overallSummary.metricDropdown.value
			for d,i in self.overallSummary.revenueEffGraph.data 
				xaxis.push(moment(d['date'],inputFormat[dropdownVal]).format(outputFormat[dropdownVal]))
				revenueEff = d.spends/d.revenue
				seriesDataRevenue[0].data.push(d['revenue'])
				seriesDataRevenue[1].data.push(revenueEff) 
				seriesDataSpends[0].data.push(d['spends']) 
				seriesDataSpends[1].data.push(revenueEff) 

			self.overallSummary.revenueEffGraph.revenueVsRevenueEffChart= 
				xAxis: xaxis
				seriesData: seriesDataRevenue

			self.overallSummary.revenueEffGraph.spendsVsRevenueEffChart = 
				xAxis: xaxis
				seriesData: seriesDataSpends
			self.isLoader = false

		toggleTab:(Tab)->
			this.selectedTab = Tab
			this.getTopBoxesDataNewAPI();

		toggleOpenSection:(section,idx)->
			if idx==0
				if(this.firstSection)
					this.firstSection = false
				else
					this.firstSection = true

			else
				if(section)
					this.processedTopBoxesData[idx].isSectionOpen= false
				else
					this.processedTopBoxesData[idx].isSectionOpen = true
			
		
		processTopBoxesData:(data)->
			self = this
			this.processedTopBoxesData =[]
			for i,section of data
				sectionWiseData={
					sectionName: '',
					id:''
					isSectionOpen: false
					boxData:[]
				}
				sectionWiseData.sectionName= i
				sectionWiseData.id= 'id'+i
				for boxData in section
					tempMetricBoxData={
						key:'',
						oldDateRange: '',
						oldValue: '',
						percentDifference:''
						prifix: '',
						suffix: '',
						value: '',
						dataSource:'',
						tooltip:''
						graphData:[]
					}
					tempMetricBoxData.key=boxData.key
					tempMetricBoxData.oldDateRange = boxData.old_date_range
					tempMetricBoxData.oldValue = boxData.old_value
					tempMetricBoxData.percentDifference = boxData.percent_difference
					tempMetricBoxData.prefix = boxData.prefix
					tempMetricBoxData.suffix = boxData.suffix
					tempMetricBoxData.value = boxData.value
					tempMetricBoxData.dataSource= boxData.data_source
					tempMetricBoxData.tooltip= boxData.tooltip
					tempGraphBoxData=
						xAxis:[],
						ylabel:''
						seriesData:[{
							name:'',
							data:[]
						},{
							name:'',
							data:[],
							color: "#A4C3BE",
							zones: [{
								value: 0
							}, {
								dashStyle: 'Dash'
							}],
							date:[]
						}],
						prefix:'',
						suffix:''
					tempGraphBoxData.seriesData[0].name= boxData.key
					tempGraphBoxData.seriesData[1].name= 'Was '
					tempGraphBoxData.prefix = boxData.prefix
					tempGraphBoxData.suffix = boxData.suffix
					for graphData in boxData.graph
						tempGraphBoxData.xAxis.push(moment(graphData.current_date).format("DD MMM"))
						tempGraphBoxData.seriesData[0].data.push(graphData.current_value)
						tempGraphBoxData.seriesData[1].data.push(graphData.comp_value)	
						tempGraphBoxData.seriesData[1].date.push( moment(graphData.comp_date).format("DD MMM"))
					tempMetricBoxData.graphData.push(tempGraphBoxData)
					sectionWiseData.boxData.push(tempMetricBoxData)
				this.processedTopBoxesData.push(sectionWiseData)
			# tempKey=[]
			# self.topGraphDataArray=[]
			# for key of self.viewTopGraphData[0]
			# 	if(key !='date')
			# 		tempKey.push(key)
			# for key in tempKey
			# 	graphData=
			# 		xAxis:[],
			# 		ylabel:''
			# 		seriesData:[{
			# 			name:'',
			# 			data:[]
			# 		},{
			# 			name:'',
			# 			data:[],
			# 			color: "#A4C3BE",
			# 			zones: [{
			# 				value: 0
			# 			}, {
			# 				dashStyle: 'Dash'
			# 			}],
			# 			date:[]
			# 		}],
			# 		prefix:'',
			# 		suffix:''
				# graphData.seriesData[0].name = key
				# graphData.seriesData[1].name = 'Was '
				# for data,idx in self.viewTopGraphData
				# 	graphData.seriesData[0].data.push(data[key].value)
				# 	graphData.seriesData[1].data.push(self.viewTopCompGraphData[idx][key].value)
				# 	graphData.seriesData[1].date.push(moment(self.viewTopCompGraphData[idx].date.value).format("DD MMM"))
				# 	graphData.xAxis.push(moment(data.date.value).format("DD MMM"))
				# 	graphData.prefix= data[key].prefix
				# 	graphData.suffix= data[key].suffix
				# self.topGraphDataArray.push(graphData)
			# console.log("done ",self.topGraphDataArray)

		processEcomGraph:()->
			self = this
			self.overallSummary.ecomGraph.transactionsVsRevenueChart = 
				xAxis: []
				seriesData: []

			self.overallSummary.ecomGraph.transactionsVsASPChart = 
				xAxis: []
				seriesData: []

			xaxis = []
			seriesDataRevenue = [
				{
					name: 'Transactions'
					data: []
				}
				{
					name: 'Revenue (Rs)',
					data: []
					color: "#FCB116"
				}
			]
			seriesDataASP = [
				{
					name: 'Transactions'
					data: []
				}
				{
					name: 'ASP (Rs)',
					data: []
					color: "#FCB116"
				}
			]
			inputFormat = { 'day': 'YYYY-MM-DD','month': 'YYYY|MM'}
			outputFormat = { 'day': 'DD MMM YYYY','month': 'MMM YYYY'}
			dropdownVal = self.overallSummary.metricDropdown.value
			for d,i in self.overallSummary.ecomGraph.data
				xaxis.push(d[dropdownVal])
				seriesDataRevenue[0].data.push(d['Transactions']) 
				seriesDataRevenue[1].data.push(d['Revenue']) 
				seriesDataASP[0].data.push(d['Transactions']) 
				seriesDataASP[1].data.push(d['Avg Selling Price']) 


			self.overallSummary.ecomGraph.transactionsVsRevenueChart = 
				xAxis: xaxis
				seriesData: seriesDataRevenue

			self.overallSummary.ecomGraph.transactionsVsASPChart = 
				xAxis: xaxis
				seriesData: seriesDataASP 
			self.isLoader = false

		processCityGraph:()->
			self = this
			self.cityTableData.chart = 
				xAxis: []
				seriesData: []

			xaxis = self.cityTableData.currentTableState.citiesData
			seriesData = [
				{
					name: 'Transactions'
					data: self.cityTableData.currentTableState.transactionsData
					
				}
			]
			if self.cityTableData.metricDropdown.value == 'Revenue'
				seriesData.push({name: 'Revenue (Rs)',data: self.cityTableData.currentTableState.revenueData, color: "#FCB116" })
			else if self.cityTableData.metricDropdown.value == 'Avg Selling Price'
				seriesData.push({name: 'ASP (Rs)',data: self.cityTableData.currentTableState.aspData, color: "#FCB116" })
			self.cityTableData.chart = 
				xAxis: xaxis
				seriesData: seriesData 

		processProductsGraph:()->
			self = this
			self.productsTableData.chart = 
				xAxis: []
				seriesData: []

			xaxis = self.productsTableData.currentProductsTableState.productsData
			seriesData = [
				{
					name: 'Quantity'
					data: self.productsTableData.currentProductsTableState.quantityData
				}
			]
			if self.productsTableData.metricDropdown.value == 'Avg Selling Price'
				seriesData.push({name: 'ASP (Rs)',data: self.productsTableData.currentProductsTableState.productsASPData, color: "#FCB116" })
			else if self.productsTableData.metricDropdown.value == 'Product Revenue'
				seriesData.push({name: 'Revenue (Rs)',data: self.productsTableData.currentProductsTableState.productsRevenueData, color: "#FCB116" }) 

			self.productsTableData.chart = 
				xAxis: xaxis
				seriesData: seriesData 

		processChannelGraph:()->
			self = this
			self.channelTableData.chart = 
				xAxis: []
				seriesData: []

			xaxis = self.channelTableData.currentChannelTableState.channelData
			seriesData = [
				{
					name: 'Transactions'
					data: self.channelTableData.currentChannelTableState.channelTransactionsData
				}
			]
			if self.channelTableData.metricDropdown.value == 'Revenue'
				seriesData.push({name: 'Revenue (Rs)', data: self.channelTableData.currentChannelTableState.channelRevenueData, color: "#FCB116" })
			else if self.channelTableData.metricDropdown.value == 'Avg Selling Price'
				seriesData.push({name: 'ASP (Rs)', data: self.channelTableData.currentChannelTableState.channelASPData, color: "#FCB116" }) 

			self.channelTableData.chart = 
				xAxis: xaxis
				seriesData: seriesData