import axios from 'axios'
import lineChart from '@/components/charts/lineChart.vue'
import moment from 'moment'
import Loader from '@/components/Loader/Loader.vue'
import Toast from 'vue-toastification'
export default {
    name: 'AccountReport',
    components: { lineChart, Loader },
    data() {
        return {
            moment: moment,
            isCompare: false,
            interval: 'day',
            widthMapper: {
                'full': 'ui sixteen wide column',
                'half': 'ui eight wide column'
            },
            dateRange: {
                startDate: moment().subtract(1, 'months'),
                endDate: moment()
            },
            singlDatePicker: false,
            prevDateRange: {
                startDate: moment().subtract(2, 'months'),
                endDate: moment().subtract(1, 'months')
            },
            allTablesData: [],
            regex: /^(https?|http):\/\/[^\s/$.?#].[^\s]*$/i,
            graphArrayFirst: [],
            graphArraySecond: [],
            specialChar: '',
            optionData: [],
            firstGraphSelectedList: [],
            secondGraphSelectedList: [],
            loader: '',
            subheaderTable: [],
            subheads: [],
            innerdata: [],
            totalFooter: [],
            WOWFooter: [],
            current: JSON.parse(localStorage.userData).clients.current,
            isDatePicketTrueAndFalse: true,
            allTableDataKeys: [],
            filterBy: "",
            isFilter: false,
            selectedNumericCampaignValue: "Impressions",
            selectedNumericMatricsValue: "greater than",
            matricsInputValue: "",
            StringMatricsInputValue: "",
            numericFiltersFields: [],
            numericFilterMatrics: [],
            stringFiltersFields: [],
            selectedStringCampaignValue: "SKU id",
            selectedStringMatricsValue: "contains",
            storeNumericFilterValue: [],
            storeStringFilterValue: [],
            isDownload:false,
        }
    },

    mounted() {
        if (this.current === 'FanCode') {
            this.singlDatePicker = true
            this.dateRange.startDate = moment(),
                this.dateRange.endDate = moment()
        }
        for (let [i, d] of this.allTablesData.entries()) {
            $('#table_dynamic_report_' + i).DataTable().destroy()
        }
        this.getTableData()
    },

    methods: {
        downloadAccountReport() {
            self = this
            self.isDownload = true
            let url = "/api/module/report"
            let payload = {
                'start_date': moment(self.dateRange.startDate).format('YYYY-MM-DD'),
                'end_date': moment(self.dateRange.endDate).format('YYYY-MM-DD'),
                'Metrics': this.storeNumericFilterValue.flat(),
                'Breakdown': this.storeStringFilterValue.flat(),
                'download': true
            }
            axios.post(url, payload).then(res => {
                self.isDownload = false
                window.location = res.data.data
            }).catch(err => {
                console.log("error",err)
                self.isDownload = false
            })
        },

        checkLink(value) {
            this.regex.test(value)
        },

        getTableData() {
            self = this
            self.loader = true
            self.allTablesData = []
            self.subheaderTable = [],
                this.subheads = []
            this.innerdata = []
            this.totalFooter = []
            this.WOWFooter = []
            var search = require("@/assets/Icons_SVG/Search.svg");
            let url = "/api/module/report"
            let payload = {
                'start_date': moment(self.dateRange.startDate).format('YYYY-MM-DD'),
                'end_date': moment(self.dateRange.endDate).format('YYYY-MM-DD'),
                'Metrics': this.storeNumericFilterValue.flat(),
                'Breakdown': this.storeStringFilterValue.flat(),
                download:false
            }
            axios.post(url, payload).then((response) => {
                self.loader = false
                // self.allTableDataKeys = Object.keys(response.data).filter((item) => item != 'data');
                self.allTablesData = response.data.data;
                if (response.data.data[0].datepicker == false) {
                    self.isDatePicketTrueAndFalse = false
                }
                if (response.data.Breakdown) {
                    self.numericFiltersFields = response.data.Metrics[0].fields;
                    self.numericFilterMatrics = response.data.Metrics[0].operations;
                    self.stringFiltersFields = response.data.Breakdown;
                }

                this.$nextTick(() => {
                    for (let [i, d] of self.allTablesData.entries()) {
                        if (d.graph) {
                            let temp = Object.keys(d.graph.length ? d.graph[0] : [])
                            self.firstGraphSelectedList.push({
                                selectedGraph: temp.length ? temp[1] : 'check'
                            })
                            self.secondGraphSelectedList.push({
                                selectedGraph: temp.length ? temp[2] : 'check'
                            })
                        }
                        if (d.subHeader_table) {
                            self.subheaderTable.push(d)
                            for (let subrows of Object.values(d.table[0])) {
                                if (typeof (subrows) === 'object') {
                                    Object.keys(subrows).forEach(key => this.subheads.push(key))
                                }
                            }
                            for (let rows of d.table) {
                                let tempContainer = []
                                for (let Headers of Object.values(rows)) {
                                    if (typeof (Headers) === 'string') {
                                        tempContainer.push(Headers)
                                    }
                                    if (typeof (Headers) === 'object') {
                                        for (let innData of Object.values(Headers)) {
                                            tempContainer.push(innData)
                                        }
                                    }
                                }
                                this.innerdata.push(tempContainer)
                                tempContainer = []
                            }
                            for (let totalRow of Object.values(d.Total)) {
                                for (let innerTotal of Object.values(totalRow)) {
                                    this.totalFooter.push(innerTotal)
                                }
                            }
                            for (let growthRow of Object.values(d["Growth WOW"])) {
                                for (let innerWOW of Object.values(growthRow)) {
                                    this.WOWFooter.push(innerWOW)
                                }
                            }
                            this.$nextTick(() => {
                                self.processSubheaderTable()
                            })
                        }
                        $('#table_dynamic_report_' + i).dataTable()
                        $('#table_dynamic_report_' + i).DataTable().destroy()
                        $('#table_dynamic_report_' + i).DataTable({
                            lengthChange: true,
                            info: false,
                            sorting: d.table.sorting == false ? false : [[0, "asc"]],
                            initComplete: function (settings, json) {
                                $('.dataTables_scrollBody tfoot tr').css({ visibility: 'collapse' });
                                $('.dataTables_scrollBody thead tr').css({ visibility: 'collapse' });
                                $('#table_dynamic_report_' + i).wrap("<div style='overflow:auto; width:100%;position:relative;'></div>");
                            },
                            language: {
                                search: "", searchPlaceholder: "Search...", paginate: {
                                    first: "",
                                    previous: "<",
                                    next: ">",
                                    last: "",
                                },
                                lengthMenu: "<h5>Show entries</h5> _MENU_"
                            },
                        })
                        $('#table_dynamic_report_' + i + "_filter > label").append(
                            `<div class='search_icon'><img src='${search}' /></div>`
                        );

                    }
                    self.processFirstGraphData()
                    self.processSecondGraphData()
                });
            }).catch(err => {
                self.loader = false
                // self.$toast.error("Technical Error!", {
                //     position: "bottom-center",
                //     timeout: false,
                //     closeOnClick: true,
                //     pauseOnFocusLoss: true,
                //     pauseOnHover: true,
                //     draggable: true,
                //     draggablePercent: 0.6,
                //     showCloseButtonOnHover: false,
                //     hideProgressBar: true,
                //     closeButton: "button",
                //     icon: true,
                //     rtl: false
                // });
            })
        },

        processSubheaderTable() {
            var search = require("@/assets/Icons_SVG/Search.svg");
            for (let i in this.subheaderTable) {
                $('#subheader_tbl' + i).dataTable();
                $('#subheader_tbl' + i).DataTable().destroy();
                $('#subheader_tbl' + i).DataTable({
                    lengthChange: true,
                    info: false,
                    sorting: [[0, 'asc']],
                    searching: true,
                    initComplete: function (settings, json) {
                        $('#subheader_tbl' + i).wrap("<div style='overflow:auto; width:100%;position:relative;'></div>");
                    },
                    language: {
                        search: "", searchPlaceholder: "Search...", paginate: {
                            first: "",
                            previous: "<",
                            next: ">",
                            last: "",
                        },
                        lengthMenu: "<h5>Show entries</h5> _MENU_"
                    },
                })
                $('#subheader_tbl' + i + "_filter > label").append(
                    `<div class='search_icon'><img src='${search}' /></div>`
                );
            }
        },

        processFirstGraphData() {
            self = this
            self.graphArrayFirst = []
            for (let [i, data] of self.allTablesData.entries()) {
                if (data.graph) {
                    let tempKey = []
                    for (let key in data.graph[0]) {
                        if (key != 'Date')
                            tempKey.push(key)
                    }
                    tempKey.reverse()
                    self.optionData.push(tempKey)
                    let oneSectionGraph = []
                    let graphData = {
                        xAxis: [],
                        seriesData: [{
                            name: '',
                            data: []
                        }],
                        prefix: '',
                        suffix: ''
                    }
                    graphData.seriesData[0].name = self.firstGraphSelectedList[i].selectedGraph
                    for (let data2 of data.graph) {
                        graphData.seriesData[0].data.push(data2[self.firstGraphSelectedList[i].selectedGraph].value)
                        graphData.xAxis.push(data2.Date.value)
                        graphData.prefix = data2[self.firstGraphSelectedList[i].selectedGraph].prefix
                        graphData.suffix = data2[self.firstGraphSelectedList[i].selectedGraph].suffix
                    }
                    oneSectionGraph.push(graphData)
                    self.graphArrayFirst.push(oneSectionGraph)
                }
                else {
                    let oneSectionGraph = []
                    self.graphArrayFirst.push(oneSectionGraph)
                }
            }
        },

        processSecondGraphData() {
            self = this
            self.graphArraySecond = []
            for (let [i, data] of self.allTablesData.entries()) {
                if (data.graph) {
                    let tempKey = []
                    for (let key in data.graph[0]) {
                        if (key != 'Date') {
                            tempKey.push(key)
                        }
                    }
                    tempKey.reverse()
                    self.optionData.push(tempKey)
                    let oneSectionGraph = []
                    let graphData = {
                        xAxis: [],
                        seriesData: [{
                            name: '',
                            data: []
                        }],
                        prefix: '',
                        suffix: ''
                    }
                    graphData.seriesData[0].name = self.secondGraphSelectedList[i].selectedGraph
                    for (let data2 of data.graph) {
                        graphData.seriesData[0].data.push(data2[self.secondGraphSelectedList[i].selectedGraph].value)
                        graphData.xAxis.push(data2.Date.value)
                        graphData.prefix = data2[self.secondGraphSelectedList[i].selectedGraph].prefix
                        graphData.suffix = data2[self.secondGraphSelectedList[i].selectedGraph].suffix
                    }
                    oneSectionGraph.push(graphData)
                    self.graphArraySecond.push(oneSectionGraph)
                }
                else {
                    let oneSectionGraph = []
                    self.graphArraySecond.push(oneSectionGraph)
                }
            }
        },
        handleApplyFilter() {
            for (let [i, d] of this.allTablesData.entries()) {
                $('#table_dynamic_report_' + i).DataTable().destroy()
            }
            this.isFilter = false;
            this.getTableData();
        },
        handleIsFilter() {
            const existingValue = this.storeNumericFilterValue.find(
                (item) => item[0].field === this.selectedNumericCampaignValue
            );

            if (existingValue) {
                this.selectedNumericMatricsValue = existingValue[0].operation;
                this.matricsInputValue = existingValue[0].value;
            }
            const existingStringValue = this.storeStringFilterValue.find(
                (item) => item[0].field === this.selectedStringCampaignValue
            );

            if (existingStringValue) {
                this.selectedStringMatricsValue = existingStringValue[0].operation;
                this.StringMatricsInputValue = existingStringValue[0].value;
            }
            this.matricsInputValue = "";
            this.StringMatricsInputValue = "";
            this.isFilter = true;
        },
        clearFilter() {
            this.isFilter = false;
            this.matricsInputValue = "";
            this.StringMatricsInputValue = "";
        },
        removeFilterValue(storeFilterValue, data) {
            const index = storeFilterValue.flat().findIndex((item) => item.field === data.field && item.operation === data.operation && item.value === data.value);

            if (index !== -1) {
                storeFilterValue.splice(index, 1);
            }
        },
        RemoveBadgeValue(data) {
            this.removeFilterValue(this.storeNumericFilterValue, data);

            for (let i = 0; i < this.storeStringFilterValue.length; i++) {
                const itemArray = this.storeStringFilterValue[i];
                const matchIndex = itemArray.findIndex((item) => item.field === data.field && item.operation === data.operation && item.value === data.value);

                if (matchIndex !== -1) {
                    this.storeStringFilterValue.splice(i, 1);
                    break;
                }
            };
            this.getTableData();
        },
        RemoveAllBadgeValue() {
            this.storeNumericFilterValue = [];
            this.storeStringFilterValue = [];
            this.getTableData();
        },
        handleFilterModal(data, storeFilterValue, selectedMatricsValueKey, matricsInputValueKey) {
            if (data.length <= 0) {
                return false;
            }

            let existingValue = storeFilterValue.find(
                (item) => item[0].field === data && item[0].operation === this[selectedMatricsValueKey]
            );

            if (existingValue) {
                // this[selectedMatricsValueKey] = existingValue[0].operation;
                this[matricsInputValueKey] = existingValue[0].value;
            } else {
                this[matricsInputValueKey] = "";
            }
        },
        handleNumericFilterModal(data) {
            this.handleFilterModal(
                data,
                this.storeNumericFilterValue,
                'selectedNumericMatricsValue',
                'matricsInputValue'
            );
        },
        handleStringFilterModal(data) {
            this.handleFilterModal(
                data,
                this.storeStringFilterValue,
                'selectedStringMatricsValue',
                'StringMatricsInputValue'
            );
        },
        handleStringMatricsFilterModal(data) {
            let existingValue = this.storeStringFilterValue.find(
                (item) => item[0].field === this.selectedStringCampaignValue && item[0].operation === data)
            if (existingValue) {
                this.StringMatricsInputValue = existingValue[0].value;
            } else {
                this.StringMatricsInputValue = ""
            }
        },
        handleNumericMatricFilterModal(data) {
            let existingValue = this.storeNumericFilterValue.find(
                (item) => item[0].field === this.selectedNumericCampaignValue && item[0].operation === data)
            if (existingValue) {
                this.matricsInputValue = existingValue[0].value;
            } else {
                this.matricsInputValue = ""
            }
        },
        handlePostFilters({ selectedCampaignValue, selectedMatricsValue, matricsInputValue, storeFilterValue }) {
            if (!matricsInputValue) {
                return false;
            }
            let existingFilter = storeFilterValue.find(
                (item) => item[0].field === selectedCampaignValue && item[0].operation === selectedMatricsValue
            );

            if (existingFilter) {
                existingFilter[0].value = isNaN(matricsInputValue) ? matricsInputValue : parseFloat(matricsInputValue);
            } else {
                storeFilterValue.push([
                    {
                        operation: selectedMatricsValue,
                        field: selectedCampaignValue,
                        value: isNaN(matricsInputValue) ? matricsInputValue : parseFloat(matricsInputValue)
                    }
                ]);
            }
        },
        postNumericalFilters() {
            this.handlePostFilters({
                selectedCampaignValue: this.selectedNumericCampaignValue,
                selectedMatricsValue: this.selectedNumericMatricsValue,
                matricsInputValue: this.matricsInputValue,
                storeFilterValue: this.storeNumericFilterValue,
            });
            this.matricsInputValue="";
            // this.isFilter = false;
        },
        handlePostStringFilters() {
            if (!this.StringMatricsInputValue) {
                return false;
            }
            this.storeStringFilterValue.push([
                {
                    operation: this.selectedStringMatricsValue,
                    field: this.selectedStringCampaignValue,
                    value: this.StringMatricsInputValue
                }
            ])
            this.StringMatricsInputValue="";
            // this.isFilter = false;
        },
    }

}