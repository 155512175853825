import moment from "moment";
import axios from "axios";
import Loader from "@/components/Loader/Loader.vue";
import dualAxisChart from '@/components/charts/dualAxisChart.vue';
import AreaChartRender from './Components/AreaChartRender.vue';
import AnalyticsHeatmap from "./Components/MoMHeatMap.vue";
import lineChart from '@/components/charts/lineChart.vue';
import heatMap from '@/components/charts/heatMap.vue';
export default {
    name: "AnalyticsSummaryGA4",
    components: {
        Loader,
        dualAxisChart,
        AreaChartRender,
        lineChart,
        heatMap,
        AnalyticsHeatmap
    },
    data() {
        return {
            loader: false,
            loader1: false,
            loader2: false,
            dateRange: {
                startDate: moment().subtract(1, 'months').format('YYYY-MM-DD'),
                endDate: moment().format('YYYY-MM-DD')
            },
            monthwise: {
                headers: [],
                data: []
            },
            topProducts: {
                headers: [],
                data: []
            },
            pagePath: {
                headers: [],
                data: []
            },
            channelOverview: {
                headers: [],
                data: []
            },
            deviceData: {
                headers: [],
                data: []
            },
            ageData: {
                headers: [],
                data: []
            },
            locationData: {
                headers: [],
                data: []
            },
            genderData: {
                headers: [],
                data: []
            },
            channelGraphData: {
                xAxis: [],
                seriesData: [],
                disableYLabel: true
            },
            genderGraphData: {
                xAxis: [],
                seriesData: [],
                disableYLabel: true
            },
            channelArr: [],
            selectedChannel: "",
            timelineArr: [
                { label: "Day", value: 'day' },
                { label: "Month", value: 'month' },
                { label: "Week", value: 'week' },
            ],
            selectedTimeline: "",
            selectedLocation: "City",
            tickPositionData: [],
            usersChartData: {},
            ASPChartData: {},
            AOVChartData: {},
            revenueChartData: {},
            transactionsChartData: {},
            chartXaxisData: [],
            plotReadyData: [],
            componentRender: 0,
            has_ecom: JSON.parse(localStorage.userData).menu.Ecomm,
            currentClient: JSON.parse(localStorage.userData).clients.current,
            intervalsList: [
                {
                    key: 'day',
                    label: 'Day'
                },
                {
                    key: 'month',
                    label: 'Month'
                },
                {
                    key: 'year',
                    label: 'Year'
                },
            ],
            selectedInterval: {
                key: 'day',
                label: 'Day'
            },
            viewTrendGraphData:'',
            trendGraphData:'',
            channelTrendDropdown:[
                { label: "All", value: 'All' },
                { label: "Paid", value: 'Paid' },
                { label: "Organic", value: 'Organic' },
                { label: "Direct", value: 'Direct' }
            ],
            selectedTrendChannel:{ label: "All", value: 'All' },
            trendMetricDropdown:[
                { label: "Total Users", value: 'total_users' },
                { label: "Total Revenue", value: 'totalRevenue' },
            ],
            selectedTrendMetric:{ label: "Total Revenue", value: 'totalRevenue' },
            viewHeatMapData:'',
            processedHeatMapData:'',
            selectedChannelSummary:'graph',
            selectedDemoGraphicsTab:'Device',
            selectedDemoGraphicsGenderSectionType:'graph'
        }
    },
    mounted() {
        this.plotReadyData = [];
        this.dataAPIs();
    },
    methods: {
        dataAPIs() {
            this.getTrendData();
            this.getMonthlyTotalData();
            this.getChannelData();
            this.getAgeData();
            this.getLocationData();
            this.getGenderData();
            if (this.has_ecom) {
                this.getTopProductsData();
                this.getDeviceData();
                this.getHeatMapData();
            }
            if (!this.has_ecom) {
                this.getPagePathData();
            }
        },

        toggleChannelSummarySection(type){
            this.selectedChannelSummary = type
            if ($.fn.DataTable.isDataTable('#channelTable')) {
                $('#channelTable').DataTable().destroy();
            }
            this.$nextTick(() => {
                this.plotTable('#channelTable', 1);
            });   

        },
        
        toggleDemoGraphicsGenderSection(type){
            this.selectedDemoGraphicsGenderSectionType = type
            if ($.fn.DataTable.isDataTable('#genderTable')) {
                $('#genderTable').DataTable().destroy();
            }
            this.$nextTick(() => {
                this.plotTable('#genderTable', 1);
            });  
        },

        changeDemographicsTab(selectedTab,tableId){
            this.selectedDemoGraphicsTab =''
            this.selectedDemoGraphicsTab = selectedTab
            // if ($.fn.DataTable.isDataTable('#'+tableId)) {
            //     $('#'+tableId).DataTable().destroy();
            // }
            // this.$nextTick(() => {
            //     this.plotTable('#'+tableId, 1);
            // });  
            // if(selectedTab=='Gender'){
            //     this.processGenderGraph(this.genderData.data)
            // }
        },

        getHeatMapData(){
            this.loader = true;
            this.viewTrendGraphData = ''
            const url = "/api/v1/analyticssummary/heatmap";
            const payload = {
               "start_date":  moment(this.dateRange.endDate).subtract(7,'days').format("YYYY-MM-DD"),
                "end_date": moment(this.dateRange.endDate).format("YYYY-MM-DD"),
            }

            axios.post(url, payload).then(res => {
                this.viewHeatMapData=res.data.body
                this.processHeatMapData();
                this.loader = false;
            }).catch(err => {
                console.log(err)
                this.loader = false
            });
        },

        setInterval(selectedInterval){
            this.selectedInterval= selectedInterval
            this.getTrendData();
        },
        getTrendData(){
            this.loader = true;
            this.viewTrendGraphData = ''
            const url = "/api/v1/analyticsummary/trend";
            const payload = {
               "start_date":  moment(this.dateRange.startDate).format("YYYY-MM-DD"),
                "end_date": moment(this.dateRange.endDate).format("YYYY-MM-DD"),
                "interval": this.selectedInterval.key,
                "channel": this.selectedTrendChannel.value,
                "metric": this.selectedTrendMetric.value
            }

            axios.post(url, payload).then(res => {
                this.viewTrendGraphData = res.data.body
                this.processTrendGraphData();
                this.loader = false;
            }).catch(err => {
                console.log(err)
                this.loader = false
            });
        },
        getMonthlyTotalData() {
            this.loader = true;
            let url, payload;

            if (this.has_ecom) {
                url = "/api/v1/analyticssummaryga4";
                payload = {
                    start_date: moment(this.dateRange.startDate).format("YYYY-MM-DD"),
                    end_date: moment(this.dateRange.endDate).format("YYYY-MM-DD"),
                    filter: "monthwise"
                }
            } else {
                url = "/api/v1/analyticssummaryga4/trafficaccount";
                payload = {
                    level_type: "account",
                    level_id: "382016237",
                    start_date: moment(this.dateRange.startDate).format("YYYY-MM-DD"),
                    end_date: moment(this.dateRange.endDate).format("YYYY-MM-DD"),
                    filter: "monthwise"
                }
            }

            axios.post(url, payload).then(res => {
                this.monthwise.headers = res.data.headers;
                this.monthwise.data = res.data.data;
                this.$nextTick(() => {
                    this.plotTable("#monthwiseTable", res.data.sort_col.sort_column, 400, res.data.sort_col.format)
                })
                this.loader = false;
            }).catch(err => {
                console.log(err);
                this.loader = false;
            })
        },
        getTopProductsData() {
            this.loader = true;
            const url = "/api/v1/analyticssummaryga4";
            let payload = {
                start_date: moment(this.dateRange.startDate).format("YYYY-MM-DD"),
                end_date: moment(this.dateRange.endDate).format("YYYY-MM-DD"),
                filter: "allproducts"
            }

            axios.post(url, payload).then(res => {
                this.topProducts.headers = res.data.headers;
                this.topProducts.data = res.data.data;
                this.$nextTick(() => {
                    this.plotTable("#topProductsTable", res.data.sort_col.sort_column)
                })
                this.loader = false;
            }).catch(err => {
                console.log(err);
                this.loader = false;
            })
        },
        getPagePathData() {
            this.loader = true;
            const url = "/api/v1/analyticssummaryga4/trafficaccount";
            const payload = {
                level_type: "account",
                level_id: "382016237",
                start_date: moment(this.dateRange.startDate).format("YYYY-MM-DD"),
                end_date: moment(this.dateRange.endDate).format("YYYY-MM-DD"),
                filter: "page"
            }

            axios.post(url, payload).then(res => {
                this.pagePath.headers = res.data.headers
                this.pagePath.data = res.data.data;
                this.$nextTick(() => {
                    this.plotTable("#pagePathTable", res.data.sort_col.sort_column, 300)
                })
                this.loader = false;
            }).catch(err => {
                console.log(err)
                this.loader = false
            });
        },
        getChannelData() {
            this.loader = true;
            let url, payload;
            if (this.has_ecom) {
                url = "/api/v1/analyticssummaryga4";
                payload = {
                    start_date: moment(this.dateRange.startDate).format("YYYY-MM-DD"),
                    end_date: moment(this.dateRange.endDate).format("YYYY-MM-DD"),
                    filter: "allchannel"
                }
            } else {
                url = "/api/v1/analyticssummaryga4/trafficaccount";
                payload = {
                    level_type: "account",
                    level_id: "382016237",
                    start_date: moment(this.dateRange.startDate).format("YYYY-MM-DD"),
                    end_date: moment(this.dateRange.endDate).format("YYYY-MM-DD"),
                    filter: "allchannel"
                }
            }

            axios.post(url, payload).then(res => {
                this.channelOverview.headers = res.data.headers;
                this.channelOverview.data = res.data.data;
                this.processChannelGraph(this.channelOverview.data);
                this.$nextTick(() => {
                    this.plotTable("#channelTable", res.data.sort_col.sort_column, 500);
                    this.getChartData()
                })
                this.loader = false;
            }).catch(err => {
                console.log(err);
                this.loader = false;
            })
        },

        getGenderData() {
            this.loader = true;
            let url, payload;

            if (this.has_ecom) {
                url = "/api/v1/analyticssummaryga4";
                payload = {
                    start_date: moment(this.dateRange.startDate).format("YYYY-MM-DD"),
                    end_date: moment(this.dateRange.endDate).format("YYYY-MM-DD"),
                    filter: "gender"
                }
            } else {
                url = "/api/v1/analyticssummaryga4/trafficaccount";
                payload = {
                    level_type: "account",
                    level_id: "382016237",
                    start_date: moment(this.dateRange.startDate).format("YYYY-MM-DD"),
                    end_date: moment(this.dateRange.endDate).format("YYYY-MM-DD"),
                    filter: "gender"
                }
            }

            axios.post(url, payload).then(res => {
                this.genderData.headers = res.data.headers;
                this.genderData.data = res.data.data;
                this.processGenderGraph(this.genderData.data);
                this.$nextTick(() => {
                    this.plotTable("#genderTable", res.data.sort_col.sort_column, 500);
                })
                this.loader = false;
            }).catch(err => {
                console.log(err);
                this.loader = false;
            })
        },

        getDeviceData() {
            this.loader = true;
            const url = "/api/v1/analyticssummaryga4";
            let payload = {
                start_date: moment(this.dateRange.startDate).format("YYYY-MM-DD"),
                end_date: moment(this.dateRange.endDate).format("YYYY-MM-DD"),
                filter: "device"
            }

            axios.post(url, payload).then(res => {
                this.deviceData.headers = res.data.headers;
                this.deviceData.data = res.data.data;
                this.$nextTick(() => {
                    this.plotTable("#deviceTable", res.data.sort_col.sort_column, 500);
                })
                this.loader = false;
            }).catch(err => {
                console.log(err);
                this.loader = false;
            })
        },
        getAgeData() {
            this.loader = true;
            let url, payload;

            if (this.has_ecom) {
                url = "/api/v1/analyticssummaryga4";
                payload = {
                    start_date: moment(this.dateRange.startDate).format("YYYY-MM-DD"),
                    end_date: moment(this.dateRange.endDate).format("YYYY-MM-DD"),
                    filter: "age"
                }
            } else {
                url = "/api/v1/analyticssummaryga4/trafficaccount";
                payload = {
                    level_type: "account",
                    level_id: "382016237",
                    start_date: moment(this.dateRange.startDate).format("YYYY-MM-DD"),
                    end_date: moment(this.dateRange.endDate).format("YYYY-MM-DD"),
                    filter: "age"
                }
            }

            axios.post(url, payload).then(res => {
                this.ageData.headers = res.data.headers;
                this.ageData.data = res.data.data;
                this.$nextTick(() => {
                    this.plotTable("#ageTable", res.data.sort_col.sort_column, 500);
                })
                this.loader = false;
            }).catch(err => {
                console.log(err);
                this.loader = false;
            })
        },
        getLocationData() {
            this.loader2 = true;
            let url, payload;

            if (this.has_ecom) {
                url = "/api/v1/analyticssummaryga4";
                payload = {
                    start_date: moment(this.dateRange.startDate).format("YYYY-MM-DD"),
                    end_date: moment(this.dateRange.endDate).format("YYYY-MM-DD"),
                    filter: "location"
                }
            } else {
                url = "/api/v1/analyticssummaryga4/trafficaccount";
                payload = {
                    level_type: "account",
                    level_id: "382016237",
                    start_date: moment(this.dateRange.startDate).format("YYYY-MM-DD"),
                    end_date: moment(this.dateRange.endDate).format("YYYY-MM-DD"),
                    filter: "location"
                }
            }

            axios.post(url, payload).then(res => {
                this.locationData.headers = res.data.headers;
                this.locationData.data = res.data.data;
                this.$nextTick(() => {
                    this.plotTable("#locationTable", res.data.sort_col.sort_column, 500);
                })
                this.loader2 = false;
            }).catch(err => {
                console.log(err);
                this.loader2 = false;
            })
        },
        getChartData() {
            this.loader1 = true;
            this.plotReadyData = [];
            let url, payload;

            if (this.has_ecom) {
                url = "/api/v1/analyticssummaryga4";
                payload = {
                    start_date: moment(this.dateRange.startDate).format("YYYY-MM-DD"),
                    end_date: moment(this.dateRange.endDate).format("YYYY-MM-DD"),
                    filter: "chart",
                    interval: this.selectedTimeline.value,
                    channel: this.selectedChannel,
                }
            } else {
                url = "/api/v1/analyticssummaryga4/trafficaccount";
                payload = {
                    level_type: "account",
                    level_id: "382016237",
                    start_date: moment(this.dateRange.startDate).format("YYYY-MM-DD"),
                    end_date: moment(this.dateRange.endDate).format("YYYY-MM-DD"),
                    filter: "chart",
                    interval: this.selectedTimeline.value,
                    channel: this.selectedChannel === 'All' ? '' : this.selectedChannel,
                }
            }

            axios.post(url, payload).then(res => {
                this.processAreaChartData(res.data.data);
                this.loader1 = false;
            }).catch(err => {
                console.log(err);
                this.loader1 = false;
            })
        },

        processChannelGraph(tableData) {
            let xaxis = [];
            let seriesDataChannel = [];

            seriesDataChannel = [
                {
                    name: 'Users',
                    data: [],
                    color: "#FCB116",
                    yAxis: 0
                },
                {
                    name: this.has_ecom ? 'Transactions' : 'Sessions',
                    data: [],
                    color: "#FFAB00",
                    yAxis: !this.has_ecom ? 0 : "",
                },
                {
                    name: this.has_ecom ? 'AOV' : 'BounceRate',
                    data: [],
                    color: "#ff8084",
                },
            ];
            if (!this.has_ecom) this.channelArr.push("All");
            for (let channelItem of tableData) {
                this.channelArr.push(channelItem.default_channel);
                xaxis.push(channelItem.default_channel);
                if (this.has_ecom) {
                    seriesDataChannel[1].data.push(channelItem['transaction'])
                    seriesDataChannel[0].data.push(channelItem['users'])
                    seriesDataChannel[2].data.push(channelItem['AOV'])
                } else {
                    seriesDataChannel[1].data.push(channelItem['Sessions'])
                    seriesDataChannel[0].data.push(channelItem['users'])
                    seriesDataChannel[2].data.push(channelItem['BounceRate'])
                }
            }
            this.channelGraphData.xAxis = xaxis;
            this.channelGraphData.seriesData = seriesDataChannel;
            this.selectedChannel = this.channelArr[0];

        },
        processHeatMapData(){

            this.processedHeatMapData = []
        
            let tempData={}
            tempData = {
                yAxis:[],
                xAxis:[],
                seriesData:[{
                    name: 'Revenue',
                    borderWidth: 2,
                    borderColor: "white",
                    data:[],
                    borderRadius: 8,
                }],
                dataLabels: {
                    enabled: true,
                    // color: 'white'
                }
            }
            tempData.xAxis = Object.keys(this.viewHeatMapData);
            tempData.xAxis.reverse()
            this.viewHeatMapData[tempData.xAxis[0]].forEach(value=>{
                tempData.yAxis.push(value.timestamp)
            })
            tempData.yAxis.forEach((time,idx1)=>{
                tempData.xAxis.forEach((data,idx)=>{
                    if(this.viewHeatMapData[data]){
                        tempData.seriesData[0].data.push([idx,idx1,this.viewHeatMapData[data][idx1].revenue])
                    }
                    else{
                        tempData.seriesData[0].data.push([time,idx,null])
                    }
                })
            })
            this.processedHeatMapData.push(tempData);
                    
        },
        processTrendGraphData(){
            this.trendGraphData={
                xAxis:[],
                disableYLabel:true,
                legend: true,
                seriesData:[]
            }
            let colorMapper =[
                "#065559", "#01695C",
                "#009688", "#42D9BC",
                "#6956E5", "#8C9EFF",
                "#FFAB00", "#F06293",
                "#1ACF85", "#FD5542",
                "#759883", "#3D5AFE",
                "#0873d2", "#d71225",
                "#98f4ce", "#496a22",
                "#eb0566", "#3254e2",
                "#f2d229", "#299605",
                "#7892a0", "#9a6cba",
                "#4efc9a", "#8711c1",
                "#e845bf", "#be6fcd",
                "#09f456", "#51fb50",
                "#4fde04", "#b276be",
                "#5724d2", "#ab790b",
                "#0e6f46", "#576f2d",
                "#9fbaf4", "#73ed7a",
                "#e6cd44", "#53da8a",
                "#85c92e", "#c5ff1e"
              ]
              
            if(this.viewTrendGraphData){
                Object.keys(this.viewTrendGraphData[0]).forEach((data,idx)=>{
                    if(data.toLowerCase()!='timestamp'){
                        this.trendGraphData.seriesData.push({
                            name: data,
                            color:data=='boAt'?'#FFAB00':colorMapper[idx],
                            data:[]
                        })
                    }
                })
                this.viewTrendGraphData.forEach((data) => {
                    this.trendGraphData.xAxis.push(data.timestamp);
                    this.trendGraphData.seriesData.forEach((seriesObject)=>{
                        seriesObject.data.push(data[seriesObject.name])
                    })
                })
            }
        },
        processGenderGraph(tableData) {
            console.log("check the ddata",tableData)
            let xaxis = [];
            let seriesDataGender = [];

            seriesDataGender = [
                {
                    name: "Users",
                    data: [],
                    color: "#FCB116",
                    yAxis: 0,
                },
                {
                    name: this.has_ecom ? 'Transactions' : "Sessions",
                    data: [],
                    color: "#FFAB00",
                    yAxis: !this.has_ecom ? 0 : "",
                },
                {
                    name: this.has_ecom ? 'AOV' : "Bounce Rate",
                    data: [],
                    color: "#ff8084",
                    yAxis: 1,
                },
                
            ];
            for (let genderItem of tableData) {
                xaxis.push(this.has_ecom ? genderItem.gender : genderItem.main);

                if (this.has_ecom) {
                    seriesDataGender[1].data.push(genderItem['transaction'])
                    seriesDataGender[2].data.push(genderItem['AOV'])
                    seriesDataGender[0].data.push(genderItem['users'])
                } else {
                    seriesDataGender[1].data.push(genderItem['Sessions'])
                    seriesDataGender[2].data.push(genderItem['BounceRate'])
                    seriesDataGender[0].data.push(genderItem['users'])
                }

            }
            this.genderGraphData.xAxis = xaxis;
            this.genderGraphData.seriesData = seriesDataGender;

        },
        processAreaChartData(data) {
            this.tickPositionData = [];
            this.usersChartData = {
                title: "Users",
                xLabel: this.currentClient === 'Merino Domestic' ? true : false,
                color: "#47A694",
                prefix: null,
                suffix: null,
                seriesData: [],
                tickWidth: this.currentClient === 'Merino Domestic' ? 1 : 0
            };
            this.ASPChartData = {
                title: this.has_ecom ? "Avg Selling Price" : "Bounce Rate",
                xLabel: this.currentClient === 'Merino Domestic' ? true : false,
                color: "#54736D",
                prefix: "₹",
                suffix: null,
                seriesData: [],
                tickWidth: this.currentClient === 'Merino Domestic' ? 1 : 0
            };
            this.AOVChartData = {
                title: this.has_ecom ? "Avg. Order Value" : "Conversions",
                xLabel: this.currentClient === 'Merino Domestic' ? true : false,
                color: "#265950",
                prefix: "₹",
                suffix: null,
                seriesData: [],
                tickWidth: this.currentClient === 'Merino Domestic' ? 1 : 0
            };
            this.revenueChartData = {
                title: this.has_ecom ? "Revenue" : "Sessions",
                xLabel: this.has_ecom ? false : true,
                color: "#79A69E",
                prefix: "₹",
                suffix: null,
                seriesData: [],
                tickWidth: this.currentClient === 'Merino Domestic' ? 1 : 0
            };
            if (this.has_ecom) {
                this.transactionRateChartData = {
                    title: "Transaction Rate",
                    xLabel: false,
                    color: "#317367",
                    prefix: null,
                    suffix: "%",
                    seriesData: [],
                    tickWidth: 0
                };
                this.transactionsChartData = {
                    title: "Transactions",
                    xLabel: true,
                    color: "#54736D",
                    prefix: "₹",
                    suffix: null,
                    seriesData: [],
                    tickWidth: 0
                };
            }
            this.chartXaxisData = [];

            if ((data.length >= 6) && (data.length <= 10)) {
                this.tickPositionData = [0, parseInt((data.length - 1) / 2), data.length - 1];
            }
            else if ((data.length >= 11) && (data.length <= 19)) {
                this.tickPositionData = [0, parseInt((data.length - 1) / 4), parseInt((data.length - 1) * 3 / 4), data.length - 1];
            }
            else if ((data.length >= 20) || (data.length <= 5)) {
                this.tickPositionData = [0, parseInt((data.length - 1) / 4), parseInt((data.length - 1) / 2), parseInt((data.length - 1) * 3 / 4), data.length - 1];
            }

            data.map(dataset => {
                this.chartXaxisData.push(dataset.date)
                this.usersChartData.seriesData.push(dataset["users"])
                if (this.has_ecom) {
                    this.ASPChartData.seriesData.push(Math.ceil(dataset["ASP"]))
                    this.AOVChartData.seriesData.push(Math.ceil(dataset["AOV"]))
                    this.revenueChartData.seriesData.push(Math.ceil(dataset["totalRevenue"]))
                    this.transactionsChartData.seriesData.push(Math.ceil(dataset["transaction"]))
                } else {
                    this.ASPChartData.seriesData.push(Math.ceil(dataset["BounceRate"]))
                    this.AOVChartData.seriesData.push(Math.ceil(dataset["Conversions"]))
                    this.revenueChartData.seriesData.push(Math.ceil(dataset["Sessions"]))
                }
            })
            this.plotReadyData = [];
            this.$nextTick(() => {
                this.plotReadyData.push(this.usersChartData, this.AOVChartData, this.revenueChartData);
                if (this.has_ecom) this.plotReadyData.push(this.transactionsChartData)
                if(!this.has_ecom) this.plotReadyData.push(this.ASPChartData);
            });
        },
        plotTable(id, sortCol, maxHeight, format="") {
            console.log("check the datadsflsdf")
            $(id).DataTable().destroy();
            
            if(format) $.fn.dataTable.moment(format);
            var search = require("@/assets/Icons_SVG/Search.svg");
            $(id).DataTable({
                searching: true,
                info: false,
                lengthChange: true,
                sorting: [[sortCol, "desc"]],
                initComplete: function () {
                    $(id).wrap(`<div style='overflow:auto; width:100%;position:relative; max-height: ${maxHeight}px;'></div>`)
                },
                language: {
                    search: "", searchPlaceholder: "Search...", paginate: {
                        first: "",
                        previous: "<",
                        next: ">",
                        last: "",
                    },
                    lengthMenu: "<h5>Show entries</h5> _MENU_"
                },
            })
            $(id + "_filter > label").append(
                `<div class='search_icon'><img src='${search}' /></div>`
            );
        },

        formatNumber(num) {
            if (isNaN(num)) return num
            let num1 = Number(num).toFixed(2);
            let num2 = Number(num1).toLocaleString();
            return num2
        }
    }
}