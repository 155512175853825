import { render, staticRenderFns } from "./GenAiAsideBar.vue?vue&type=template&id=4c38dff9&scoped=true&lang=pug"
import script from "./GenAiAsideBar.js?vue&type=script&lang=js&external"
export * from "./GenAiAsideBar.js?vue&type=script&lang=js&external"
import style0 from "./GenAiAsideBar.vue?vue&type=style&index=0&id=4c38dff9&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4c38dff9",
  null
  
)

export default component.exports