import Vue from 'vue'
import VueRouter from 'vue-router'

// Login
import Login  from '@/pages/Login/Login.vue'
import Register  from '@/pages/Register/Register.vue'

//KPi Summary For Merino
import KPISummary from '@/pages/KPISummary/KPISummary.vue'
import OverallSummary from '@/pages/OverallSummary/OverallSummary.vue'

// import Dashboard from '../pages/Dashboard/Dashboard.vue'
import BusinessSummary from '../pages/BusinessSummary/BusinessSummary.vue'
import Privacy from '../pages/Privacy/Privacy.vue'

//adwords
import Summary from '../pages/Adwords/Summary.vue'
import GroupData from '../pages/Adwords/GroupData/GroupData.vue'
import CampaignSummary from '../pages/Adwords/Campaign/CampaignSummary.vue'
import AdSummary from '../pages/Adwords/AdSummary/AdSummary.vue'
import DeviceSummary from '../pages/Adwords/DeviceSummary/DeviceSummary.vue'
import SearchVolumeSummary from '../pages/Adwords/SearchVolume/SearchVolumeSummary.vue'
import BrandWiseSearchVolume from '../pages/Adwords/SearchVolume/BrandWiseSearchVolume.vue'

//Analytics
import AnalyticsSummary from '../pages/Analytics/AnalyticsSummary/AnalyticsSummaryToggle.vue'
import EHeatMap from '../pages/Analytics/EHeatMap/EHeatMap.vue'
import WeeklySummary from '../pages/Analytics/WeeklySummary/WeeklySummary.vue'

// Facebook
import FacebookSummary from '../pages/Facebook/FacebookSummary.vue'
import FacebookCreatives from '../pages/Facebook/FacebookCreatives/FacebookCreatives.vue'
import CreativesReport from '@/pages/Facebook/FacebookCreatives/CreativesReport.vue';
import AllCreatives from '@/pages/Facebook/FacebookCreatives/AllCreatives.vue';
import AdDetails from '@/pages/Facebook/FacebookCreatives/AdDetails.vue';

//Reports
import AccountReport from '../pages/Reports/AccountReport/AccountReport.vue'
import CustomReport from '../pages/Reports/CustomReport/CustomReport.vue'
import CampaignReport from '@/pages/Reports/CampaignReport/CampaignReport.vue'
import MediaReport from '@/pages/Reports/MediaReport/MediaReport.vue'
import ClientReport from '@/pages/ClientReport/ClientReport.vue'

// Amazon
import AmazonSearch from '../pages/Amazon/Search/AmazonSearch.vue'
import AmazonDisplay from '../pages/Amazon/Display/AmazonDisplay.vue'
import AmazonBudgetRule from '../pages/Amazon/BudgetRule/AmazonBudgetRule.vue'
import AmazonReport from '../pages/Amazon/Report/AmazonReport.vue'
import AmazonSilver from '@/pages/Amazon/AmazonSilver/AmazonSilver.vue'
import AMS from '@/pages/Amazon/AMS/AMS.vue'
import AMSAnalytics from '@/pages/Amazon/AMS/AMSAnalytics.vue'
import AdsetSummary from '@/pages/Amazon/Adset/AdsetSummary.vue'
import Sleepwell from '@/pages/Amazon/Sleepwell/Sleepwell.vue';
import Alert from '@/pages/Amazon/Alert/Alert.vue';

//Flipkart
import FlipkartSummary from '@/pages/Flipkart/Summary/FlipkartSummary.vue'
import FlipkartSearch from '@/pages/Flipkart/Search/FlipkartSearch.vue'
import FlipkartDisplay from '@/pages/Flipkart/Display/FlipkartDisplay.vue'
import FlipkartReport from '@/pages/Flipkart/Report/FlipkartReport.vue'
import FlipkartSleepwell from '@/pages/Flipkart/Sleepwell/FlipkartSleepwell.vue';


// Myntra
import MyntraSummary from '@/pages/Myntra/Summary/MyntraSummary.vue'

//Snapchat
import SnapchatSummary from '@/pages/Snapchat/Summary/Summary.vue'
// Settings
import UserSettings from '@/pages/Settings/UserSettings/UserSettings.vue'

//custom reports
import AmazonAccountSummary from '@/pages/Amazon/AccountSummary/AmazonAccountSummary.vue'

// Recovery Password
import RecoveryPassword from '@/pages/RecoveryPassword/RecoveryPassword.vue'

// PPT 
import PPT from '@/pages/PPT/PPT.vue'

//Programmetic
import Programatic from '@/pages/Programmetic/Programatic.vue'

// test performance report 
// import PerformanceSummary from '@/pages/PerformanceSummary/PerformanceSummary.vue'

//Admin
import Users from '@/pages/Admin/Users.vue'
import Clients from '@/pages/Admin/Clients.vue'
import MediaData from '@/pages/Admin/Data/MediaData/MediaData.vue'
import KPITracker from '@/pages/Admin/Data/KPITracker/KPITracker.vue'
import DBMData from '@/pages/Admin/Data/DBM/DBMData.vue'
import FBData from '@/pages/Admin/Data/FBData/FBData.vue'
import ProgramaticData from '@/pages/Admin/Data/ProgrammaticData/ProgrammaticData.vue'
import PilotReport from '@/pages/Admin/Data/PilotReport/PilotReport.vue'
import CustomData from '@/pages/Admin/Data/CustomData/CustomData.vue'
import AdwordMapping from '@/pages/Admin/Mapping/AdwordMapping/AdwordMapping.vue'
import FacebookMapping from '@/pages/Admin/Mapping/FacebookMapping/FacebookMapping.vue'
import GAMapping from '@/pages/Admin/Mapping/GAMapping/GAMapping.vue'
import LinkedInMapping from '@/pages/Admin/Mapping/LinkedInMapping/LinkedInMapping.vue'
import ReportDownload from '@/pages/Admin/Tools/ReportDownload.vue'
import AdScreenshot from '@/pages/Admin/Tools/AdScreenshot.vue'

// Demo
import Demo from '@/pages/Demo.vue'
import AdvancedFilter from '@/components/Filter/AdvancedFilter.vue'

// Plugin
import ConnectAccounts from '@/pages/Plugin/ConnectAccounts.vue';
import connectedSuccessfully from '@/pages/Plugin/connectedSuccessfully.vue';
import connectedFailed from '@/pages/Plugin/connectionfail.vue';
import summaryForPlugin from '@/pages/SummaryForPlugin/Summary.vue'

//GenAi
import GenAiAsideBar from "@/pages/GenAi/GenAiAsideBar.vue";

//Shopify
import shopifySummary from '@/pages/Shopify/Summary.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/shopify-summary',
    name: "Summary",
    component: shopifySummary
  },
  {
    path: '/summary/:platform',
    name: "Summary",
    component: summaryForPlugin
  },
  {
    path: '/connected-successfully',
    name: "Connected Sucessfully",
    component: connectedSuccessfully
  },
  {
    path: '/connected-failed',
    name: "Connected Failed",
    component: connectedFailed
  },
  {
    path: '/connect-your-accounts',
    name: "Connect your accounts",
    component: ConnectAccounts
  },
  {
    path: '/demo',
    name: "Demo",
    component: Demo
  },
  {
    path: '/filter',
    name: "Filter",
    component: AdvancedFilter
  },
  {
    path: '/login',
    name: 'Login',
    component: Login
  },
  {
    path: '/register',
    name: 'Register',
    component: Register

  },
  {
    path: '/privacy',
    name: 'Privacy',
    component: Privacy
  },
  {
    path: '/recovery/:id',
    name: 'Recovery Password',
    component: RecoveryPassword
  },
  //Business Summary
  {
    path: '/business-summary',
    name: 'Business Summary',
    auth: false,
    component: BusinessSummary
  },

  //Merino KPI Summary
  {
    path: '/kpi-summary',
    name: 'KPI Summary',
    auth: false,
    component: KPISummary
  },
  // cmo dashboard
  {
    path: '/cmo-dashboard',
    name: 'CMO Dashboard',
    auth: false,
    component: OverallSummary
  },
  // {
  //   path: '/performance-summary',
  //   name: 'Performance Summary',
  //   auth: false,
  //   component: PerformanceSummary
  // },
  //Adwords
  {
    path: '/summary',
    name: 'Adwords Summary',
    component: Summary
  },
  {
    path: '/keyword-data',
    name: 'Keyword Data',
    component: GroupData
  },
  {
    path: '/campaign-summary',
    name: 'Campaign Summary',
    component: CampaignSummary
  },
  {
    path: '/adword-summary',
    name: 'Adword Summary',
    component: AdSummary
  },
  {
    path: '/device-summary',
    name: 'Device Summary',
    component: DeviceSummary
  },
  {
    path: '/search-volume-summary',
    name: 'Search Volume',
    component: SearchVolumeSummary
  },
  {
    path: '/search-volume/:brandName',
    name: 'Brand Wise',
    component: BrandWiseSearchVolume
  },
  //Analytics
  {
    path: '/analytics-summary',
    name: 'Analytics Summary',
    component: AnalyticsSummary
  },
  {
    path: '/heatmap',
    name: 'E-commerce Heatmap',
    component: EHeatMap
  },
  {
    path: '/weekly-summary',
    name: 'Ecommerce Weekly Summary',
    component: WeeklySummary
  },
  //facebook
  {
    path: '/facebook-summary',
    name: 'Facebook Summary',
    component: FacebookSummary
  },
  {
    path: '/facebook-creatives',
    name: 'Facebook Creatives',
    component: FacebookCreatives
  },
  {
    path: '/all-creatives',
    name: 'All Creatives',
    component: AllCreatives
  },
  {
    path: '/ad/:adId',
    name: 'AD Details',
    component: AdDetails
  },
  //reports
  {
    path: '/custom-report',
    name: 'Custom Report',
    component: CustomReport
  },
  {
    path: '/account-report',
    name: 'Account Report',
    component: AccountReport
  },
  {
    path: '/client-report',
    name: 'Account Summary',
    component: ClientReport
  },
  {
    path: '/campaign-report',
    name: 'Campaign Report',
    component: CampaignReport
  },
  {
    path: '/media-report',
    name: 'Media Report',
    component: MediaReport
  },
  // Amazon 
  {
    path: '/amazon-search',
    name: 'Summary',
    component: AmazonSearch
  },
  // {
  //   path: '/amazon-display',
  //   name: 'Amazon Display',
  //   component: AmazonDisplay
  // },
  {
    path: '/amazon-budget-rule',
    name: 'Amazon Budget Rule',
    component: AmazonBudgetRule
  },
  {
    path: '/amazon-report',
    name: 'Amazon Report',
    component: AmazonReport
  },

  {
    path: '/amazon-silver',
    name: 'Amazon Silver',
    component: AmazonSilver
  },
  {
    path: '/ams',
    name: 'AMS',
    component: AMS
  },
  {
    path: '/performance-summary',
    name: 'Performance Summary',
    component: Sleepwell
  },
  {
    path: '/alert',
    name: 'Alert',
    component: Alert
  },
  {
    path: '/ams-analytics',
    name: 'AMS Analytics',
    component: AMSAnalytics
  },
  {
    path: '/adset-summary',
    name: 'Adset Summary',
    component: AdsetSummary
  },
  {
    path: '/amazon-account-summary',
    name: 'Account Summary',
    component: AmazonAccountSummary
  },

  //Flipkart 
  {
    path: '/flipkart-summary',
    name: 'Summary',
    component: FlipkartSummary
  },
  {
    path: '/flipkart-search',
    name: 'Flipkart Search',
    component: FlipkartSearch
  },
  {
    path: '/flipkart-display',
    name: 'Flipkart Display',
    component: FlipkartDisplay
  },
  {
    path: '/flipkart-report',
    name: 'Flipkart Report',
    component: FlipkartReport
  },
  {
    path: '/flipkart-sleepwell',
    name: 'Sleepwell',
    component: FlipkartSleepwell
  },

  // Myntra 
  {
    path: '/myntra-summary',
    name: "Summary",
    component: MyntraSummary
  },

  //Myntra
  {
    path: '/snapchat-summary',
    name: 'Summary',
    component: SnapchatSummary
  },
  // Settings
  {
    path: '/setting-user',
    name: 'User Settings',
    component: UserSettings
  },
  //PPT
  {
    path: '/PPT',
    name: 'PPT',
    component: PPT
  },
  //Programmatic
  {
    path: '/programmatic',
    name: 'Programmatic',
    component: Programatic
  },
  //Gen-AI
  {
    path: "/gen-ai",
    name: "WeavrWise",
    component: GenAiAsideBar,
  },
  
  // admin section
  {
    path: '/users',
    name: 'Users',
    component: Users
  },
  {
    path: '/clients',
    name: 'Clients',
    component: Clients
  },
  {
    path: '/fb-data',
    name: 'FB Data',
    component: FBData
  },
  {
    path: '/dbm-data',
    name: 'DBM Data',
    component: DBMData
  },
  {
    path: '/kpi-tracker',
    name: 'KPI Tracker',
    component: KPITracker
  },
  {
    path: '/programmatic-admin',
    name: 'Programmatic Data',
    component: ProgramaticData
  },
  {
    path: '/pilot-report',
    name: 'Pilot Report',
    component: PilotReport
  },
  {
    path: '/media-data',
    name: 'Media Data',
    component: MediaData
  },
  {
    path: '/custom-data',
    name: 'Custom Data',
    component: CustomData
  },
  {
    path: '/adword-mapping',
    name: 'Adword Mapping',
    component: AdwordMapping
  },
  {
    path: '/fb-mapping',
    name: 'Facebook Mapping',
    component: FacebookMapping
  },
  {
    path: '/ga-mapping',
    name: 'GA Mapping',
    component: GAMapping
  },
  {
    path: '/linkedin-mapping',
    name: 'LinkedIn Mapping',
    component: LinkedInMapping
  },
  {
    path: '/report-download',
    name: 'Report Download',
    component: ReportDownload
  },
  {
    path: '/ad-screenshot',
    name: 'Ad Screenshot',
    component: AdScreenshot
  },

]

if (localStorage.fb_creative_report_name) {
  routes.push({
    path: `/${localStorage.fb_creative_report_name.replaceAll(" ", "-")}`,
    name: `${localStorage.fb_creative_report_name}`,
    component: CreativesReport
  })
}

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
