//--library
import axios from "axios";

// --components
import TypingLoader from "./TypingLoader/TypingLoader.vue";
import SuggestedPrompts from "./SuggestedPrompts/SuggestedPrompts.vue";
import { computed } from "vue";

export default {
  name: "GenAiChatBox",
  props: {
    // onNewSessionClick: Function,
    toggleMaximize: {
      type: Function,
      required: true,
    },
    sendPrompt: {
      type: Function,
      required: true,
    },
    getChats: {
      type: Function,
      required: true,
    },
    getPrompts: {
      type: Function,
      required: true,
    },
    loadActiveSession: {
      type: Function,
      required: true,
    },
    activeSession: {
      type: Object,
      required: true,
    },
    promptQuery: {
      type: String,
      default: ''
    },
    isAms: {
      type: String,
      default: ''
    },
    isNewSession: Boolean,
    chatBotLoader: Boolean,
    isOpen: Boolean,
  },
  components: { TypingLoader, SuggestedPrompts },
  data() {
    return {
      loader: false,
      userQuery: this.promptQuery,
      copyText: "",
      showTooltip: false,
      savedSearchHistory: [],
      isMaximize: false,
      response: false,
      newChatsMessages: [],
      toggleShowHide: "true",
      page: 1,
      isPageLoading: false,
      isStoreChatId: "",
    };
  },
  mounted() {
    this.isStoreChatId = localStorage.getItem("chatId")
    const chatBody = this.$refs.messagesContainer;
    this.$nextTick(() => {
      chatBody.scrollTop = chatBody.scrollHeight;
    });
  },
  // beforeDestroy() {
  // const container = this.$refs.chatBox;
  // if (container) {
  //   container.removeEventListener("scroll", this.handleScroll);
  // }
  // },
  watch: {
    promptQuery(newVal) {
      this.userQuery = newVal;
    },
    activeSession() {
      this.scrollToBottom();
    }
  },
  methods: {
    copyMessage(message, index) {
      navigator.clipboard
        .writeText(message)
        .then(() => {
          this.showTooltip = index;
          setTimeout(() => {
            this.showTooltip = false;
          }, 2000);
        })
        .catch((err) => {
          console.error("Error copying text: ", err);
        });
    },
    setToggleMaximize(val) {
      const currentRoute = localStorage.getItem("currentRoute");
      this.$emit("isAMS", val);
      this.isNewSession = false;
      this.isMaximize = false;
      this.toggleMaximize(this.isMaximize);
      const routeName = this.isOpen ? '/gen-ai' : currentRoute;
      this.$router.push({ path: routeName });
      this.isOpen = false;
    },
    scrollToBottom() {
      setTimeout(() => {
        const container = this.$refs.messagesContainer;
        if (container) {
          container.scrollTo({
            top: container.scrollHeight,
            behavior: 'smooth'
          });
        }
      }, 100);
    },
    checkScrollTop() {
      const chatBody = this.$refs.messagesContainer;
      if (chatBody.scrollTop === 0) {
        this.isPageLoading = true;
        // this.getChats(this.activeSession.chat_id, this.page)
        console.log(this.page, "page");
      }
    },
    // handleScroll() {
    //   const container = this.$refs.scrollContainer;
    //   console.log(container.offsetTop);
    //   if (container.offsetTop === 0) {
    //     // this.loadMoreData();
    //     console.log("You can call api to load next page data");
    //   }
    // },
    // scrollToBottom() {
    //   this.$nextTick(() => {
    //     this.$refs.userQueryInput.focus();
    //     let container = this.$refs.chatBox;
    //     container.scrollTop = container.scrollHeight;
    //   });
    // },
    //------adding a prefined prompt into a userQuery-------
    handlePreDefinedPrompt(query) {
      this.userQuery = query;
      this.$refs.userQueryInput.focus();
    },
    //--------------Sending user's message------------
    sendQuery(query, chat_id = null) {
      this.scrollToBottom();

      if (query.trim() === "") {
        this.userQuery = "";
        return;
      }

      if (chat_id == null) {
        this.$emit("update-session", false);
      }
      this.scrollToBottom();
      this.userQuery = "";
      this.userMessage = query;
      this.response = true;
      const payload = {
        query: query,
        chat_id: chat_id,
      };
      let url = "/api/v1/chatbot/query";
      axios
        .post(url, payload)
        .then((res) => {
          if (this.isOpen && this.isAms == 'ams') {
            // console.log(res.data.data.chats[0].chat_id, "res data");
            localStorage.setItem("chatId", res.data.data.chats[0].chat_id)
          }
          if (chat_id == null) {
            this.activeSession.messages = res.data.data.chats[0].messages;
            this.getChats(chat_id);
            this.loadActiveSession(res.data.data.chats[0].chat_id, false);
            this.userMessage = "";
            this.userQuery = "";
            this.sendPrompt(prompt = "")
            this.response = false;
            this.scrollToBottom();
            return false;
          }
          if (this.activeSession.messages) {
            this.activeSession.messages.push(
              ...res.data.data.chats[0].messages,
            )
          } else {
            this.activeSession.messages = [...res.data.data.chats[0].messages];
          }

          // this.getChats(chat_id);
          this.userMessage = "";
          this.userQuery = "";
          this.response = false;
          this.scrollToBottom();
        })
        .catch((err) => {
          this.response = false;
          this.isNewSession = true;
          this.$toast.error("Server Error", {
            position: "top-center",
            timeout: 2000,
            closeOnClick: true,
            pauseOnFocusLoss: true,
            pauseOnHover: true,
            draggable: true,
            draggablePercent: 0.6,
            showCloseButtonOnHover: false,
            hideProgressBar: true,
            closeButton: "button",
            icon: true,
            rtl: true,
          });
          this.scrollToBottom();
          console.log(err);
        });
    },
    // -----------add user's message as Prompt--------
    adAsPrompt(prompt, group_heading = "New Prompt Heading", group_id = null) {
      const payload = { prompt: prompt, group_heading: group_heading };
      if (group_id) {
        payload["group_id"] = group_id;
      }
      const url = "/api/v1/chatbot/add/prompt"; //to add the message as new Prompt
      this.loader = true;
      axios
        .post(url, payload)
        .then((res) => {
          this.loader = false;
          console.log("added as prompt: ", res);
          this.$toast.success(res.data.summary, {
            position: "top-center",
            timeout: 2000,
            closeOnClick: true,
            pauseOnFocusLoss: true,
            pauseOnHover: true,
            draggable: true,
            draggablePercent: 0.6,
            showCloseButtonOnHover: false,
            hideProgressBar: true,
            closeButton: "button",
            icon: true,
            rtl: false,
          });
          this.getPrompts();
        })
        .catch((error) => {
          this.loader = false;
          console.error(error);
          this.$toast.error(res.data.summary, {
            position: "top-center",
            timeout: 2000,
            closeOnClick: true,
            pauseOnFocusLoss: true,
            pauseOnHover: true,
            draggable: true,
            draggablePercent: 0.6,
            showCloseButtonOnHover: false,
            hideProgressBar: true,
            closeButton: "button",
            icon: true,
            rtl: true,
          });
        });
    },
  },
};
