import Loader from "@/components/Loader/Loader.vue";
import moment from "moment";
import axios from "axios";
import { Modal } from "flowbite";
import scatterGraph from "@/components/charts/scatterChart.vue";
import Toast from "@/components/Toast/Toast.vue";

export default {
  name: "AMS",
  components: {
    Loader,
    scatterGraph,
    Toast,
  },
  data() {
    return {
      dataLoader: false,
      campLoader: false,
      isFilter: false,
      filterBy: "",
      selectedNumericCampaignValue: "ACOS",
      numericFiltersFields: [],
      selectedNumericMatricsValue: "greater than",
      matricsInputValue: "",
      selectedStringCampaignValue: "Campaign",
      stringFiltersFields: [],
      selectedStringMatricsValue: "contains",
      StringMatricsInputValue: "",
      storeNumericFilterValue: [],
      storeStringFilterValue: [],
      selectedCampaignAgeMetricValue: "campaign_age",
      selectedCampaignAgeValue: "New",
      storeCampaignAge: [],
      activeCampaign: true,
      dateRange: {
        startDate: moment().subtract(1, "week").format("YYYY-MM-DD"),
        endDate: moment().format("YYYY-MM-DD"),
      },
      modalDateRange: {
        startDate: moment().subtract(1, "week").format("YYYY-MM-DD"),
        endDate: moment().format("YYYY-MM-DD"),
      },
      postCampaign: [],
      rulesTableHeader: [],
      rulesTableData: [],
      campaignTableHeader: [],
      campaignTableData: [],
      rulesData: [],
      campaignRules: [],
      ruleName: [],
      rulesOption: [],
      ruleId: [],
      ruleHeading: [],
      rule_id: "",
      metrics: [],
      campaign: [],
      campaignType: [],
      campType: "",
      // ruleModal: undefined,
      campModal: undefined,
      movingAvgModal: undefined,
      campRuleId: "",
      emailError: false,
      isAms: "ams",
      // selectedLastDate: "",
      // selectedLastMonth: "",
      // radioSelcted: "",
      // checkLastDays: "",
      // intervalSelected: "1",
      date: "1",
      addEmail: [],
      // rules: [
      //   {
      //     rule_name: "",
      //     conditions: [
      //       {
      //         metric: "",
      //         operation: "",
      //         value: null,
      //       },
      //     ],
      //   },
      // ],
      // camp_rules: [
      //   {
      //     rule_id: "",
      //     campaign_id: "",
      //     campaign_name: "",
      //     campaign_type: "",
      //     radioSelcted: "",
      //     selectedLastDate: "",
      //     selectedLastDay: "",
      //     selectedLastMonth: "",
      //     interval: "",
      //     time: "",
      //     alertName: "",
      //     email: [],
      //   },
      // ],
      postCamp: [],
      tempEmail: "",
      dropdownOptions2: [
        "Greater Than",
        "Greater Equal",
        "Equal",
        "Less Equal",
        "Less Than",
      ],
      intervalOptions: ["Daily", "Weekly"],
      LastDate: [
        2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21,
        22, 23, 24, 25, 26, 27, 28, 29, 30,
      ],
      LastDay: [
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday",
        "Sunday",
      ],
      dataLoader1: false,
      viewCampaignData: "",
      tableRawData: [],
      selectedCampaignTypeData: [
        {
          key: "Sponsored Products",
          label: "sp",
        },
        {
          key: "Sponsored Brands",
          label: "sb",
        },
      ],
      campaignOptions: [
        {
          key: "Sponsored Products",
          label: "sp",
        },
        {
          key: "Sponsored Brands",
          label: "sb",
        },
        {
          key: "Sponsored Display",
          label: "sd",
        },
      ],
      topInsightData: [
        {
          id: 1,
          data: "Improve Available to purchase rate by restocking these 5 items on amazon",
          btn_1: "Category",
          btn_2: "Sub Category"
        },
        {
          id: 2,
          data: "Revenue for Product Bar & More from amazon over the last 30 days, exhibits a downward trend",
          btn_1: "Revenue",
          btn_2: "Product"
        },
        {
          id: 2,
          data: "Revenue for Product Bar & More from amazon over the last 30 days, exhibits a downward trend",
          btn_1: "Revenue",
          btn_2: "Product"
        },
        {
          id: 2,
          data: "Revenue for Product Bar & More from amazon over the last 30 days, exhibits a downward trend",
          btn_1: "Revenue",
          btn_2: "Product"
        },
        {
          id: 2,
          data: "Revenue for Product Bar & More from amazon over the last 30 days, exhibits a downward trend",
          btn_1: "Revenue",
          btn_2: "Product"
        },
        {
          id: 2,
          data: "Revenue for Product Bar & More from amazon over the last 30 days, exhibits a downward trend",
          btn_1: "Revenue",
          btn_2: "Product"
        },
        {
          id: 2,
          data: "Revenue for Product Bar & More from amazon over the last 30 days, exhibits a downward trend",
          btn_1: "Revenue",
          btn_2: "Product"
        },

      ],
      campaignGraphData: "",
      campaignAgeMetaData: "",
      scatterChartOption: ["Sales", "Spends"],
      selectedScatterChart: "Sales",
      headersList: [],
      selectedHeaders: [],
      insightsHeader: [],
      insightsData: [],
      customRanges: [],
      categoryWiseTableData: "",
      MovingAvgGraphData: [],
      selectedMovAvgData: "Daily",
      modalCampaingName: "",
      graphDropdown: "",
      selectedGraphOptions: "ACOS",
      moviginAvgData: "",
      viewUpdatedAMSRefreshData: "",
      intervalID: null,
      targetACOS: "",
      selectedCampaignAge: [],
      campaignAgeOptions: ["New", "Intermediate", "Old"],
      quadrantData: [],
      quadrantHeader: [],
      filteredQuadrantData: [],
      quadrantModal: "",
      quadrantModalTitle: "",
      maxSales: "",
      runTimes: [],
      // alertCampReports: {
      //   headers: [],
      //   data: [],
      // },
      // failedCampaignReports: {
      //   headers: [],
      //   data: [],
      // },
      // alertDateRange: {
      //   startDate: moment().subtract(1, "week").format("YYYY-MM-DD"),
      //   endDate: moment().format("YYYY-MM-DD"),
      // },
      // failedCampModal: "",
      // failedCampModalTitle: "",
      selectedCampaign: '',
      isColumns: true,
    };
  },
  created() {
    const today = new Date();
    const yesterday = new Date(
      today.getFullYear(),
      today.getMonth(),
      today.getDate() - 1
    );
    const monthStartDate = new Date(
      today.getFullYear(),
      today.getMonth() - 1,
      today.getDate() + 0
    );
    const thisMonthStartDate = new Date(
      today.getFullYear(),
      today.getMonth(),
      1
    );
    const thisMonthEndDate = new Date();
    const lastMonthStartDate = new Date(
      today.getFullYear(),
      today.getMonth() - 1,
      1
    );
    const lastMonthEndDate = new Date(today.getFullYear(), today.getMonth(), 0);
    const thisYearStartDate = new Date(today.getFullYear(), 0, 1);
    const thisYearEndDate = new Date(today.getFullYear(), 11, 31);
    const lastYearStartDate = new Date(today.getFullYear() - 1, 0, 1);
    const lastYearEndDate = new Date(today.getFullYear() - 1, 11, 31);
    const quarterStartMonth = Math.floor(today.getMonth() / 3) * 3;
    const quarterStartDate = new Date(
      today.getFullYear(),
      quarterStartMonth,
      1
    );

    // Calculate the first day of the current week (Sunday)
    const firstDayOfCurrentWeek = new Date(today);
    firstDayOfCurrentWeek.setDate(today.getDate() - today.getDay());

    // Calculate the last day of the previous week (Saturday)
    const lastDayOfLastWeek = new Date(firstDayOfCurrentWeek);
    lastDayOfLastWeek.setDate(firstDayOfCurrentWeek.getDate() - 1);

    // Calculate the first day of last week (Sunday)
    const firstDayOfLastWeek = new Date(lastDayOfLastWeek);
    firstDayOfLastWeek.setDate(lastDayOfLastWeek.getDate() - 6);

    this.customRanges = {
      Today: [today, today],
      Yesterday: [yesterday, yesterday],
      Month: [monthStartDate, thisMonthEndDate],
      "Last Week": [firstDayOfLastWeek, lastDayOfLastWeek],
      "Last month": [lastMonthStartDate, lastMonthEndDate],
      "Last Year": [lastYearStartDate, lastYearEndDate],
      MTD: [thisMonthStartDate, today],
      QTD: [quarterStartDate, today],
      YTD: [thisYearStartDate, today],
    };
  },
  computed: {
    // Access the stored date range from Vuex
    amsSelectedDateRange() {
      return this.$store.state.filter.amsSelectedDateRange;
    },
  },
  mounted() {
    if (this.amsSelectedDateRange.startDate && this.amsSelectedDateRange.endDate) {
      this.dateRange.startDate = this.amsSelectedDateRange.startDate;
      this.dateRange.endDate = this.amsSelectedDateRange.endDate;
    }
    if (localStorage.campStatus) localStorage.removeItem("campStatus");
    if (localStorage.selectedCampaign)
      localStorage.removeItem("selectedCampaign");
    if (localStorage.dateRange) localStorage.removeItem("dateRange");
    this.getCampaignTableData("");
    this.initializeModal();
    // this.getAlertRules();
    // this.getCampaignRule();
    this.getUpdatedAMSRefreshData();
    this.checkStatus();
    // this.getAlertReports();
  },
  methods: {
    applyFilters(filter) {
      this.isFilter = false;
      this.isColumns = false;
      this.getCampaignTableData(filter);
      localStorage.setItem("dateRange", JSON.stringify(this.dateRange));
      // this.dateRange.startDate = moment(this.dateRange.startDate).format("YYYY-MM-DD")
      // this.dateRange.endDate = moment(this.dateRange.endDate).format("YYYY-MM-DD")
      // this.$store.dispatch('filter/setSelectedAMSDate',this.dateRange)
    },
    handleBack() {
      this.selectedCampaign = ''
      $("#campaignTable-tbl").DataTable().column(0).search("").draw();
    },
    handleSetAlert() {
      this.$router.push("alert")
    },
    validEmail(email) {
      var re =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(email);
    },

    formateDateRange(timestamp) {
      return moment(timestamp).format("lll");
    },

    updateDateRange() {
      this.radioSelcted = this.selectedLastDate;
    },
    checkStatus() {
      // this.intervalID = setInterval(this.getUpdatedAMSRefreshData, 9000);
    },
    rowColor(rowData, idx) {
      if (idx != "Budget Usage") {
        return false;
      }

      if (rowData["Budget Usage"].value > 90) {
        return "#FFBEB5";
      } else if (
        rowData["Budget Usage"].value < 90 &&
        rowData["Budget Usage"].value > 75
      ) {
        return "#B2BDFF";
      } else {
        return "transparent";
      }
    },
    initializeModal() {
      const $targetMoving = document.getElementById("movingAvg-modal");
      const movingOptions = {
        placement: "center",
        backdrop: "dynamic",
        backdropClasses: "bg-gray-900 bg-opacity-50 fixed inset-0 z-40",
        closable: true,
      };
      const $targetQuadrant = document.getElementById("quadrant-modal");
      const quadrantOptions = {
        placement: "center",
        backdrop: "dynamic",
        backdropClasses: "bg-gray-900 bg-opacity-50 fixed inset-0 z-40",
        closable: true,
      };
      // const $targetFailedCamp = document.getElementById("failedCamp-modal");
      // const failedCampOptions = {
      //   placement: "center",
      //   backdrop: "dynamic",
      //   backdropClasses: "bg-gray-900 bg-opacity-50 fixed inset-0 z-40",
      //   closable: true,
      //   onClose: () => {
      //     this.failedCampModalTitle = "";
      //     this.failedCampaignReports.headers = [];
      //     this.failedCampaignReports.data = [];
      //   },
      // };
      // this.ruleModal = new Modal($targetRule, ruleOptions);
      // this.campModal = new Modal($targetCamp, campOptions);
      this.movingAvgModal = new Modal($targetMoving, movingOptions);
      this.quadrantModal = new Modal($targetQuadrant, quadrantOptions);
      // this.failedCampModal = new Modal($targetFailedCamp, failedCampOptions);
    },
    // addButton() {
    //   this.ruleModal.show();
    //   // this.getAlertRules();
    // },
    goToAnalysis(campaignName) {
      this.$router.push("ams-analytics");
      // localStorage.setItem('dateRange', JSON.stringify(this.dateRange));
      localStorage.setItem("selectedCampaign", campaignName);
      localStorage.setItem("campStatus", this.activeCampaign);
    },
    postTargetACOSData(status) {
      this.dataLoader1 = true;
      let url = "/api/amazon/targetACOS";
      let payload = {};
      if (status) {
        this.targetACOS = "";
        payload = {
          reset: status,
        };
      } else {
        if (!this.targetACOS) {
          this.dataLoader1 = false;
          this.$toast.error("Please enter target ACOS", {
            position: "bottom-center",
            timeout: 3000,
            closeOnClick: true,
            pauseOnFocusLoss: true,
            pauseOnHover: true,
            draggable: true,
            draggablePercent: 0.6,
            showCloseButtonOnHover: false,
            hideProgressBar: true,
            closeButton: "button",
            icon: true,
            rtl: false,
          });
          return false;
        }
        payload = {
          target_acos: this.targetACOS,
          reset: status,
        };
      }
      axios
        .post(url, payload)
        .then((res) => {
          this.targetACOS = "";
          this.getCampaignTableData("");
          this.initializeModal();
          // this.getAlertRules();
          // this.getCampaignRule();
          this.dataLoader1 = false;
        })
        .catch((err) => {
          console.log(err);

          this.dataLoader1 = false;
        });
    },
    movingAvg(data) {
      this.modalDateRange.startDate = moment(this.dateRange.startDate).format(
        "YYYY-MM-DD"
      );
      this.modalDateRange.endDate = moment(this.dateRange.endDate).format(
        "YYYY-MM-DD"
      );
      this.modalCampaingName = data["Campaign"].value;
      this.movingAverageData();
      this.movingAvgModal.show();
    },
    hideMovingAvgModal() {
      this.movingAvgModal.hide();
    },

    // addRuleButton() {
    //   this.campModal.hide();
    //   this.ruleModal.show();
    // },
    // addCampButton() {
    //   this.campModal.show();
    //   // this.getCampaignRule();
    //   // this.getCampaign();
    // },
    // hideCampModal() {
    //   this.campModal.hide();
    // },
    // hideRuleModal() {
    //   this.ruleModal.hide();
    // },
    // closeRuleModal() {
    //   this.ruleModal.hide();
    // },
    formatNumber(num) {
      if (isNaN(num)) return num;
      let num1 = Number(num).toFixed(2);
      let num2 = Number(num1).toLocaleString();
      return num2;
    },
    formatDateRange() {
      this.dateRange.startDate = moment(this.dateRange.startDate).format(
        "YYYY-MM-DD"
      );
      this.dateRange.endDate = moment(this.dateRange.endDate).format(
        "YYYY-MM-DD"
      );
      this.modalDateRange.startDate = moment(
        this.modalDateRange.startDate
      ).format("YYYY-MM-DD");
      this.modalDateRange.endDate = moment(this.modalDateRange.endDate).format(
        "YYYY-MM-DD"
      );
    },
    formatDates() {
      this.formatDateRange();
    },
    RemoveBadgeValue(item) {
      this.selectedHeaders = this.selectedHeaders.filter(header => header !== item);
    },
    RemoveAllBadgeValue() {
      this.selectedHeaders = [];
    },
    filterCampTable(campName) {
      this.selectedCampaign = ''
      this.selectedCampaign = campName
      document.body.scrollTop = 0;
      document.documentElement.scrollTop = 0;
      $("#campaignTable-tbl")
        .DataTable()
        .column(0)
        .search(campName, false, false)
        .draw();
    },
    getUpdatedAMSRefreshData() {
      let intervalTempId = this.intervalID;
      this.viewUpdatedAMSRefreshData = "";
      let url = "/api/amazon/GetRefreshAmsDataStatus";
      axios
        .get(url)
        .then((res) => {
          this.viewUpdatedAMSRefreshData = res.data.data;
          // if (
          //   this.viewUpdatedAMSRefreshData.refresh_status === "failed" ||
          //   this.viewUpdatedAMSRefreshData.refresh_status === "completed"
          // ) {
          //   clearInterval(intervalTempId);
          // }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getStatusColor(status) {
      if (status == "completed") {
        return "green";
      } else if (status == "running") {
        return "orange";
      } else if (status == "failed") {
        return "red";
      }
    },
    refrencePageData() {
      this.dataLoader1 = true;
      let url = "/api/amazon/RefreshAmsData";
      axios
        .post(url, {})
        .then((res) => {
          this.dataLoader1 = false;
          this.getUpdatedAMSRefreshData();
          this.checkStatus();
        })
        .catch((err) => {
          console.log(err);

          this.dataLoader1 = false;
        });
    },
    filterQuadrantData(quadrant) {
      this.filteredQuadrantData = this.quadrantData.filter(
        (row) => Number(row.Quadrant) === quadrant
      );
      this.quadrantModalTitle = `Campaigns for Quadrant - ${Number(quadrant)}`;
      this.quadrantModal.show();
      this.$nextTick(() => {
        this.processTable("#quadrant_table", 3);
      });
    },
    hideQuadrantModal() {
      this.quadrantModal.hide();
      $("#quadrant_table").DataTable().destroy();
      this.filteredQuadrantData = [];
      this.quadrantModalTitle = "";
    },
    // getAlertReports() {
    //   this.dataLoader = true;
    //   $("#reportsTable").DataTable().destroy();
    //   this.alertCampReports.headers = [];
    //   this.alertCampReports.data = [];
    //   const url = "/api/amazon/alert/name_list";
    //   const payload = {
    //     start_date: moment(this.alertDateRange.startDate).format("YYYY-MM-DD"),
    //     end_date: moment(this.alertDateRange.endDate).format("YYYY-MM-DD"),
    //   };

    //   axios
    //     .get(url, { params: payload })
    //     .then((res) => {
    //       this.alertCampReports.headers = res.data.data.headers;
    //       this.alertCampReports.data = res.data.data.report_names;
    //       this.$nextTick(() => {
    //         this.processTable("#reportsTable");
    //       });
    //       this.dataLoader = false;
    //     })
    //     .catch((err) => {
    //       this.dataLoader = false;
    //       console.log(`Error in getting alert reports::${err}`);
    //     });
    // },
    // getFailedCampaigns(report) {
    //   this.dataLoader = true;

    //   const url = "/api/amazon/alert/reports";
    //   const payload = {
    //     start_date: moment(this.alertDateRange.startDate).format("YYYY-MM-DD"),
    //     end_date: moment(this.alertDateRange.endDate).format("YYYY-MM-DD"),
    //     report_name: report,
    //   };
    //   this.failedCampModalTitle = `Failed Campaigns - ${report}`;

    //   axios
    //     .get(url, { params: payload })
    //     .then((res) => {
    //       this.failedCampaignReports.headers = res.data.data.headers;
    //       this.failedCampaignReports.data = res.data.data.data;
    //       this.dataLoader = false;
    //       this.failedCampModal.show();
    //       this.$nextTick(() => {
    //         this.processTable("#failedCampTable", 0, "300px");
    //       });
    //     })
    //     .catch((err) => {
    //       this.dataLoader = false;
    //       console.log(`Error in getting failed campaigns::${err}`);
    //     });
    // },
    getCampaignTableData(sentimentFilter) {
      this.viewCampaignData = "";
      this.categoryWiseTableData = "";
      this.insightsHeader = [];
      this.insightsData = [];
      this.tableRawData = [];
      this.campLoader = true;
      let url = "/api/amazon/campaigns/get_campagins";
      let adType = [];
      if (this.selectedHeaders.length && !this.selectedHeaders.includes("Campaign")) {
        this.selectedHeaders.unshift("Campaign");
      }
      let payload = {
        start_date: this.dateRange.startDate,
        end_date: this.dateRange.endDate,
        fields: this.selectedHeaders,
        campaigns_age_filter_list: this.storeCampaignAge.flat(),
        // flag_filter: [],
        numerical_filter_list: this.storeNumericFilterValue.flat(),
        string_filter_list: this.storeStringFilterValue.flat()
      };
      if (sentimentFilter) payload.flag_filter.push(sentimentFilter);
      if (this.activeCampaign) payload = { ...payload, camp_state: "Enabled" };
      else payload = { ...payload, camp_state: "All" };

      if (!this.selectedCampaignTypeData.length) {
        adType = [];
      } else {
        this.selectedCampaignTypeData.forEach((element) => {
          adType.push(element.key);
        });
      }
      payload = { ...payload, ad_type: adType };

      axios
        .post(url, payload)
        .then((res) => {
          this.viewCampaignData = res.data.data;
          this.tableRawData = res.data.data.data;
          this.campaignAgeMetaData = res.data.data.campaign_age_meta;
          this.headersList = res.data.data.campaign_header;
          if (this.isColumns) {
            this.selectedHeaders = this.headersList;
          }
          this.insightsHeader = res.data.data.insight_graph_header;
          this.insightsData = res.data.data.insight_data;
          this.numericFiltersFields = res.data.data.numerical_filters;
          this.stringFiltersFields = res.data.data.string_filters;
          this.headersList.splice(0, 1);
          this.categoryWiseTableData = res.data.data.ad_type_data;
          this.maxSales = res.data.data.rounded_max_sales;
          this.$nextTick(() => {
            this.processGaraphData(res.data.data);
            this.processTable("#campaignTable-tbl", 4);
            this.processTable("#insights-table", 1);
            this.processTable("#category-table", 1);
          });
          this.campLoader = false;
        })
        .catch((err) => {
          console.log("err", err);
          this.campLoader = false;
        });
    },
    filterBudgetUsage(sentiment) {
      let filteredSentimentData = [];

      switch (sentiment) {
        case "negative":
          filteredSentimentData = this.tableRawData.filter(
            (row) =>
              row.flag.value === "negative" || row.Campaign.value == "Total"
          );
          $("#campaignTable-tbl").DataTable().destroy();
          this.viewCampaignData.data = filteredSentimentData;
          this.processTable("#campaignTable-tbl", 4);
          break;

        case "neutral":
          filteredSentimentData = this.tableRawData.filter(
            (row) =>
              row.flag.value === "negative" || row.Campaign.value == "Total"
          );
          $("#campaignTable-tbl").DataTable().destroy();
          this.viewCampaignData.data = filteredSentimentData;
          this.processTable("#campaignTable-tbl", 4);
          break;

        case "all":
          $("#campaignTable-tbl").DataTable().destroy();
          this.viewCampaignData.data = this.tableRawData;
          this.processTable("#campaignTable-tbl", 4);
          break;
      }
    },
    processGaraphData(data) {
      this.campaignGraphData = {};
      this.campaignGraphData = {
        xAxisName: "Sales",
        yAxisName: "ACOS",
        zAxisName: "Spends",
        targetACOS: data.min_max.ACOS.target_acos,
        avgACOS: data.min_max.ACOS.mean,

        ACOSMaxValue: "",
        xAxis: {
          min: data.min_max[this.selectedScatterChart].min,
          max: data.min_max[this.selectedScatterChart].max,
          mean: data.min_max[this.selectedScatterChart].mean,
        },
        yAxis: {
          min: data.min_max.ACOS.min,
          max: data.min_max.ACOS.max,
          mean: data.min_max.ACOS.target_acos,
        },
        zAxis: {
          min: data.min_max.Spends.min,
          max: data.min_max.Spends.max,
          mean: data.min_max.Spends.mean,
        },
        seriesData: [
          {
            name: "ACOS VS " + this.selectedScatterChart,
            data: [],
            colorByPoint: true,
          },
        ],
      };

      let i = 0;
      let j = 0;
      let k = 0;
      let l = 0;
      for (let row of data.graph_data) {
        if (row.campaign_age.value === "New") {
          this.campaignGraphData.seriesData[0].data.push({
            name: row["Campaign"].value,
            x: row[this.selectedScatterChart].value,
            y: row.ACOS["value"],
            z: row.Spends["value"],
            color: "#1ACF85",
          });
          i++;
        }
        if (row.campaign_age.value === "Old") {
          this.campaignGraphData.seriesData[0].data.push({
            name: row["Campaign"].value,
            x: row[this.selectedScatterChart].value,
            y: row.ACOS["value"],
            z: row.Spends["value"],
            color: "#00A1C9",
          });
          j++;
        }
        if (row.campaign_age.value === "Intermediate") {
          this.campaignGraphData.seriesData[0].data.push({
            name: row["Campaign"].value,
            x: row[this.selectedScatterChart].value,
            y: row.ACOS["value"],
            z: row.Spends["value"],
            color: "#FFAB00",
          });
          k++;
        }
        if (row.campaign_age.value === "NA") {
          this.campaignGraphData.seriesData[0].data.push({
            name: row["Campaign"].value,
            x: row[this.selectedScatterChart].value,
            y: row.ACOS["value"],
            z: row.Spends["value"],
            color: "#667BFE",
          });
          l++;
        }
      }
    },

    toggleDropdown() {
      this.showDropdown = !this.showDropdown;
    },

    // addItem(index) {
    //   this.rules[index].conditions.push({
    //     metric: "",
    //     operation: "",
    //     value: null,
    //   });
    // },

    // addRules() {
    //   this.rules.push({
    //     rule_name: "",
    //     conditions: [
    //       {
    //         metric: "",
    //         operation: "",
    //         value: null,
    //       },
    //     ],
    //   });
    // },
    removeRules(idx) {
      this.rules.splice(idx, 1);
    },
    multipleEmail(campIndex) {
      if (!this.validEmail(this.tempEmail)) {
        this.emailError = true;
        return false;
      }
      this.emailError = false;
      // this.camp_rules[campIndex].email.push(this.tempEmail);
      this.tempEmail = "";
    },
    // removeMultipleEmail(campIndex, emailIndex) {
    //   this.camp_rules[campIndex].email.splice(emailIndex, 1);
    // },

    // getCampaignRule() {
    //   this.dataLoader1 = true;
    //   this.campaignType = [];
    //   this.campaignTableData = [];
    //   this.campaignTableHeader = [];
    //   let url = "/api/amazon/alert/campaign_rule";
    //   axios
    //     .get(url)
    //     .then((res) => {
    //       const resData = res.data;
    //       this.dataLoader1 = false;
    //       this.campaignType = resData.data.campaign_types;
    //       if (this.campaignType.length != 0) {
    //         this.campaignType.unshift({
    //           key: "ALL",
    //           label: "all",
    //         });
    //       }

    //       this.campaignTableHeader = resData.data.headers;
    //       this.campaignTableData = resData.data.campaign_rules;
    //       this.$nextTick(() => {
    //         this.processTable("#campaignRuleTable");
    //       });
    //       this.dataLoader1 = false;
    //     })
    //     .catch((err) => {
    //       console.log(err);
    //       this.dataLoader1 = false;
    //     });
    // },

    // getCampaign(type) {
    //   this.dataLoader = true;
    //   let key = [];
    //   this.campaign = [];
    //   if (type.length) {
    //     if (type[0].label == "all") {
    //       for (let data of this.campaignType) {
    //         if (data.label != "all") key.push(data.label);
    //       }
    //     } else {
    //       for (let data of type) {
    //         key.push(data.label);
    //       }
    //     }
    //   } else {
    //     this.dataLoader = false;
    //   }

    //   let url = "/api/amazon/alert/campaigns?ad_type=" + key.toString();
    //   axios
    //     .get(url)
    //     .then((res) => {
    //       const resData = res.data;
    //       this.dataLoader = false;
    //       this.campaign = resData.data;
    //       if (this.campaign) {
    //         this.campaign.unshift({
    //           camp_id: "all",
    //           camp_name: "ALL",
    //         });
    //       }
    //     })
    //     .catch((err) => {
    //       console.log("err", err);
    //       this.dataLoader = false;
    //     });
    // },
    // postCampaignRule() {
    //   this.postCampaign = [];
    //   this.dataLoader = true;
    //   for (let Element of this.camp_rules) {
    //     let temp = {
    //       rule_id: Element.rule_id.map((value) => {
    //         return value.rule_id;
    //       }),
    //       campaign_id:
    //         Element.camp_id[0].camp_id == "all"
    //           ? []
    //           : Element.camp_id.map((value) => {
    //             return value.camp_id;
    //           }),
    //       campaign_name:
    //         Element.camp_id[0].camp_name == "ALL"
    //           ? []
    //           : Element.camp_id.map((value) => {
    //             return value.camp_name;
    //           }),
    //       campaign_type:
    //         Element.campaign_type[0].label == "all"
    //           ? []
    //           : Element.campaign_type.map((value) => {
    //             return value.label;
    //           }),
    //       interval: Element.intervalSelected,
    //       run_at: Element.time,
    //       alert_name: Element.alertName,
    //       email: Element.email,
    //       data_duration:
    //         Element.radioSelcted == 0 ? 0 : Element.selectedLastDate,
    //     };
    //     if (this.tempEmail) {
    //       if (!this.validEmail(this.tempEmail)) {
    //         this.emailError = true;
    //         this.dataLoader = false;
    //         return false;
    //       }
    //       Element.email.push(this.tempEmail);
    //     }

    //     if (temp.interval == "weekly") {
    //       Object.assign(temp, { day_of_week: Element.selectedLastDay });
    //     }
    //     if (temp.interval == "monthly") {
    //       Object.assign(temp, { day_of_month: Element.selectedLastMonth });
    //     }
    //     this.postCampaign.push(temp);
    //   }
    //   let url = "/api/amazon/alert/campaign_rule";

    //   let payload = {
    //     camp_rules: this.postCampaign,
    //   };

    //   axios
    //     .post(url, payload)
    //     .then((res) => {
    //       this.camp_rules = [
    //         {
    //           rule_id: "",
    //           campaign_id: "",
    //           campaign_name: "",
    //           campaign_type: "",
    //           radioSelcted: "",
    //           selectedLastDate: "",
    //           selectedLastDay: "",
    //           selectedLastMonth: "",
    //           interval: "",
    //           time: "",
    //           alertName: "",
    //           email: "",
    //         },
    //       ];
    //       this.$toast.success("Rule Added Successfully", {
    //         position: "bottom-center",
    //         timeout: 5000,
    //         closeOnClick: true,
    //         pauseOnFocusLoss: true,
    //         pauseOnHover: true,
    //         draggable: true,
    //         draggablePercent: 0.6,
    //         showCloseButtonOnHover: false,
    //         hideProgressBar: true,
    //         closeButton: "button",
    //         icon: true,
    //         rtl: false,
    //       });
    //       this.hideCampModal();
    //       // this.getCampaignRule();
    //       location.reload();
    //       this.dataLoader = false;
    //     })
    //     .catch((err) => {
    //       console.log(err);
    //       this.dataLoader = false;
    //     });
    // },
    // getAlertRules() {
    //   this.dataLoader = true;
    //   this.rulesTableData = "";
    //   let url = "/api/amazon/alert/rules";
    //   axios
    //     .get(url)
    //     .then((res) => {
    //       const resData = res.data;
    //       this.metrics = resData.data.metrices;
    //       this.rulesTableHeader = resData.data.headers;
    //       this.rulesTableData = resData.data.dashboard_data;
    //       this.runTimes = resData.data.run_time_options;
    //       this.rulesTableHeader.push({ key: "action", label: "Action" });
    //       if (resData.data.rules.length)
    //         this.campaignRules = resData.data.rules;
    //       this.$nextTick(() => {
    //         this.processTable("#ruleTable");
    //       });
    //       this.dataLoader = false;
    //     })
    //     .catch((err) => {
    //       console.log(err);
    //       this.dataLoader = false;
    //     });
    // },
    // postAlertRules() {
    //   this.dataLoader = true;
    //   let url = "/api/amazon/alert/rules";

    //   if (!this.rules[0].rule_name) {
    //     this.$toast.error("Rule name can't be empty", {
    //       position: "bottom-center",
    //       timeout: 5000,
    //       closeOnClick: true,
    //       pauseOnFocusLoss: true,
    //       pauseOnHover: true,
    //       draggable: true,
    //       draggablePercent: 0.6,
    //       showCloseButtonOnHover: false,
    //       hideProgressBar: true,
    //       closeButton: "button",
    //       icon: true,
    //       rtl: false,
    //     });
    //     return;
    //   }

    //   let payload = {
    //     rules: [],
    //   };

    //   if (this.rules[0].conditions.length > 1) {
    //     this.rules[0].conditions.forEach((condition, index) => {
    //       if (!condition.metric || !condition.operation || !condition.value) {
    //         this.rules[0].conditions.splice(index, 1);
    //       }
    //     });
    //   } else {
    //     if (
    //       !this.rules[0].conditions[0].metric ||
    //       !this.rules[0].conditions[0].operation ||
    //       !this.rules[0].conditions[0].value
    //     ) {
    //       this.$toast.error("Rule properties can't be empty", {
    //         position: "bottom-center",
    //         timeout: 5000,
    //         closeOnClick: true,
    //         pauseOnFocusLoss: true,
    //         pauseOnHover: true,
    //         draggable: true,
    //         draggablePercent: 0.6,
    //         showCloseButtonOnHover: false,
    //         hideProgressBar: true,
    //         closeButton: "button",
    //         icon: true,
    //         rtl: false,
    //       });
    //       return;
    //     }
    //   }

    //   payload["rules"] = this.rules;
    //   axios
    //     .post(url, payload)
    //     .then((res) => {
    //       // alert("Rule Added Successfully");
    //       this.dataLoader = false;
    //       this.rules = [
    //         {
    //           rule_name: "",
    //           conditions: [
    //             {
    //               metric: "",
    //               operation: "",
    //               value: null,
    //             },
    //           ],
    //         },
    //       ];
    //       this.$toast.success("Added Successfully", {
    //         position: "bottom-center",
    //         timeout: 5000,
    //         closeOnClick: true,
    //         pauseOnFocusLoss: true,
    //         pauseOnHover: true,
    //         draggable: true,
    //         draggablePercent: 0.6,
    //         showCloseButtonOnHover: false,
    //         hideProgressBar: true,
    //         closeButton: "button",
    //         icon: true,
    //         rtl: false,
    //       });
    //       // this.getAlertRules();
    //       this.closeRuleModal();
    //     })
    //     .catch((err) => {
    //       console.log(err);
    //       this.dataLoader = false;
    //     });
    // },
    // deleteAlertRules(deleteRuleId) {
    //   this.dataLoader = true;
    //   let url = "/api/amazon/alert/rule_delete";
    //   let payload = {
    //     rule_id: deleteRuleId,
    //   };
    //   if (confirm("Are you sure you want to delete?")) {
    //     axios
    //       .post(url, payload)
    //       .then((res) => {
    //         // this.getAlertRules();
    //         this.dataLoader = false;
    //       })
    //       .catch((err) => {
    //         console.log(err);
    //         this.dataLoader = false;
    //       });
    //   } else {
    //     this.dataLoader = false;
    //     return false;
    //   }
    // },

    // deleteCampaignRules(deleteCampId) {
    //   this.dataLoader = true;
    //   let url = "/api/amazon/alert/camp_rule_delete";
    //   let payload = {
    //     alert_id: deleteCampId,
    //   };
    //   if (confirm("Are you sure you want to delete?")) {
    //     axios.post(url, payload).then((res) => {
    //       // this.campaignTableData = this.campaignTableData.filter(
    //       //   (row) => row.alert_id !== deleteCampId
    //       // );
    //       // this.getCampaignRule();
    //       location.reload();
    //       this.dataLoader = false;
    //     });
    //   } else {
    //     this.dataLoader = false;
    //     return false;
    //   }
    // },

    decodeHTML(html) {
      txt = document.createElement("textarea");
      txt.innerHTML = html;
      return txt.value;
    },

    processTable(id, sortCol = 0) {
      $(id).DataTable().destroy();
      var search = require("@/assets/Icons_SVG/Search.svg");
      var clear = require("@/assets/clear.png");
      $(id).DataTable({
        searching: true,
        info: true,
        sorting: [[sortCol, "desc"]],
        // scrollY: scrollVertical || false,
        initComplete: function (settings, json) {
          $(id).wrap(
            "<div style='overflow:auto; width:100%;position:relative;'></div>"
          );
        },
        language: {
          search: "",
          searchPlaceholder: "Search...",
          paginate: {
            first: "",
            previous: "<",
            next: ">",
            last: "",
          },
          lengthMenu: "<h5>Show entries</h5> _MENU_",
        },
      });

      $(id + "_filter > label").append(`
    <div class='icons_wrapper' style='position: relative;'>
     <div class='search_icon'>
        <img src='${search}' />
      </div>
      
    <div class='close_icon'>
        <img src='${clear}' />
      </div>
    </div>
  `);

      $(id + "_filter .search_icon").css({
        position: "relative",
      });

      $(id + "_filter .close_icon").css({
        width: "20px",
        position: "absolute",
        cursor: "pointer",
        top: 3,
        visibility: "hidden",
      });

      function adjustCloseIconPosition() {
        const screenWidth = window.innerWidth;

        if (screenWidth <= 1536) {
          $(id + "_filter .close_icon").css({
            right: "180px",
          });
        } else {
          $(id + "_filter .close_icon").css({
            right: "222px",
          });
        }
      }

      adjustCloseIconPosition();
      $(window).resize(adjustCloseIconPosition);

      $(id + "_filter input").on("input", function () {
        if ($(this).val().length > 0) {
          $(id + "_filter .close_icon").css("visibility", "visible");
        } else {
          $(id + "_filter .close_icon").css("visibility", "hidden");
        }
      });

      $(id + "_filter .close_icon").on("click", function () {
        $(id + "_filter input").val("");
        $(id).DataTable().search("").draw();
        $(id + "_filter .close_icon").css("visibility", "hidden");
      });
    },
    changeDateRange() {
      this.formatDateRange();
      this.movingAverageData();
    },
    handleIsFilter() {
      const existingValue = this.storeNumericFilterValue.find(
        (item) => item[0].field === this.selectedNumericCampaignValue
      );

      if (existingValue) {
        this.selectedNumericMatricsValue = existingValue[0].operation;
        this.matricsInputValue = existingValue[0].value;
      }
      this.matricsInputValue = "";
      this.StringMatricsInputValue = "";
      this.isFilter = true;
    },
    clearFilter() {
      this.isFilter = false;
      this.matricsInputValue = "";
      this.StringMatricsInputValue = "";
    },
    handleFilterModal(data, storeFilterValue, selectedMatricsValueKey, matricsInputValueKey) {
      if (data.length <= 0) {
        return false;
      }

      let existingValue = storeFilterValue.find(
        (item) => item[0].field === data && item[0].operation === this[selectedMatricsValueKey]
      );

      if (existingValue) {
        this[matricsInputValueKey] = existingValue[0].value;
      } else {
        this[matricsInputValueKey] = "";
      }
    },
    handleNumericFilterModal(data) {
      this.handleFilterModal(
        data,
        this.storeNumericFilterValue,
        'selectedNumericMatricsValue',
        'matricsInputValue'
      );
    },
    handleStringFilterModal(data) {
      this.handleFilterModal(
        data,
        this.storeStringFilterValue,
        'selectedStringMatricsValue',
        'StringMatricsInputValue'
      );
    },
    handleNumericMatricFilterModal(data) {
      let existingValue = this.storeNumericFilterValue.find(
        (item) => item[0].field === this.selectedNumericCampaignValue && item[0].operation === data)
      if (existingValue) {
        this.matricsInputValue = existingValue[0].value;
      } else {
        this.matricsInputValue = ""
      }
    },
    handleStringMatricsFilterModal(data) {
      let existingValue = this.storeStringFilterValue.find(
        (item) => item[0].field === this.selectedStringCampaignValue && item[0].operation === data)
      if (existingValue) {
        this.StringMatricsInputValue = existingValue[0].value;
      } else {
        this.StringMatricsInputValue = ""
      }
    },
    handlePostFilters({ selectedCampaignValue, selectedMatricsValue, matricsInputValue, storeFilterValue }) {
      if (!matricsInputValue) {
        return false;
      }
      let existingFilter = storeFilterValue.find(
        (item) => item[0].field === selectedCampaignValue && item[0].operation === selectedMatricsValue
      );

      if (existingFilter) {
        existingFilter[0].value = isNaN(matricsInputValue) ? matricsInputValue : parseFloat(matricsInputValue);
      } else {
        storeFilterValue.push([
          {
            operation: selectedMatricsValue,
            field: selectedCampaignValue,
            value: isNaN(matricsInputValue) ? matricsInputValue : parseFloat(matricsInputValue)
          }
        ]);
      }
    },
    postNumericalFilters() {
      this.handlePostFilters({
        selectedCampaignValue: this.selectedNumericCampaignValue,
        selectedMatricsValue: this.selectedNumericMatricsValue,
        matricsInputValue: this.matricsInputValue,
        storeFilterValue: this.storeNumericFilterValue,
      });
      this.isFilter = false;
    },
    handlePostStringFilters() {
      if (!this.StringMatricsInputValue) {
        return false;
      }
      this.storeStringFilterValue.push([
        {
          operation: this.selectedStringMatricsValue,
          field: this.selectedStringCampaignValue,
          value: this.StringMatricsInputValue
        }
      ])
      this.isFilter = false;
    },
    handlePostCampaignAgeFilters() {
      const existValue = this.storeCampaignAge.find((item) =>
        item.some((campaignAge) => campaignAge.value === this.selectedCampaignAgeValue)
      );
      if (existValue) {
        return false;
      }
      this.storeCampaignAge.push([
        {
          operation: "",
          field: this.selectedCampaignAgeMetricValue,
          value: this.selectedCampaignAgeValue
        }
      ])
      this.isFilter = false;
    },
    removeFilterValue(storeFilterValue, data) {
      const index = storeFilterValue.flat().findIndex((item) => item.field === data.field && item.operation === data.operation && item.value === data.value);

      if (index !== -1) {
        storeFilterValue.splice(index, 1);
      }
    },
    RemoveFilterBadgeValue(data) {
      this.removeFilterValue(this.storeNumericFilterValue, data);
      this.removeFilterValue(this.storeCampaignAge, data);

      for (let i = 0; i < this.storeStringFilterValue.length; i++) {
        const itemArray = this.storeStringFilterValue[i];
        const matchIndex = itemArray.findIndex((item) => item.field === data.field && item.operation === data.operation && item.value === data.value);

        if (matchIndex !== -1) {
          this.storeStringFilterValue.splice(i, 1);
          break;
        }
      }
    },
    RemoveAllFilterBadgeValue() {
      this.storeNumericFilterValue = [];
      this.storeStringFilterValue = [];
      this.storeCampaignAge = [];
    },
  },
};
