import axios from 'axios';
import lineChart from '@/components/charts/lineChart.vue';
import Loader from '@/components/Loader/Loader.vue';
export default {
    name: 'Summary',
    components: {
        Loader, lineChart
    },
    data() {
        return {
            loader: false,
            dateRange: {
                startDate: moment().subtract(4, 'week').format("YYYY-MM-DD"),
                endDate: moment().format("YYYY-MM-DD")
            },
            viewAllProductCatalogue: '',
            isModalOpenforShopifyProduct: '',
            viewProductVariantData: '',
            selectedProductName:''
        }
    },

    created() {
        // this.initializeModal();
        this.getProductCataloqueData();
    },
    mounted() {
        this.initializeModal();
    },
    methods: {
        initializeModal() {
            const $shopifyModal = document.getElementById("shopify-modal");
            const shopifyModalOptions = {
                placement: "center",
                backdrop: "dynamic",
                backdropClasses: "bg-gray-900 bg-opacity-50 fixed inset-0 z-40",
                closable: false,
                onClose: () => {
                    //    this.shopifyStoreName=''
                },
            };
            this.isModalOpenforShopifyProduct = new Modal($shopifyModal, shopifyModalOptions);
        },
        openProductModal() {
            $('#variant_reports_table').DataTable().destroy();
            if ($.fn.DataTable.isDataTable('#variant_reports_table')) {
                $('#variant_reports_table').DataTable().destroy();
            }
            this.$nextTick(() => {
                this.getProductWiseProductVariantData();
            });  
            this.isModalOpenforShopifyProduct.show();
            
        },
        hideRuleModal() {
            $("#variant_reports_table").DataTable().destroy();
            this.isModalOpenforShopifyProduct.hide();
        },
        formatNumber(num) {
            if (isNaN(num)) { return num }
            let num1 = Number(num).toFixed(2);
            let num2 = Number(num1).toLocaleString();
            return num2
        },

        getProductWiseProductVariantData(productId,productName) {
            this.selectedProductName = productName
            this.viewProductVariantData = ''
            this.loader = true
            const payload = {
                product_id: productId
            }
            const url = "/api/shopify/product_variant_catalogue"
            axios.post(url, payload).then(res => {
                this.isModalOpenforShopifyProduct.show();
                this.viewProductVariantData = res.data.data
                $("#variant_reports_table").DataTable().destroy();
                this.$nextTick(() => {
                    this.processTable('#variant_reports_table')
                })
                this.loader = false
            }).catch((err) => {
                console.log("Err Plugin PlayAccountsData")
                this.loader = false
            })
        },

        getProductCataloqueData() {
            this.viewAllProductCatalogue = {}
            this.loader = true

            const url = "/api/shopify/product_catalogue"
            axios.post(url, {}).then(res => {
                this.viewAllProductCatalogue = res.data.data
                this.$nextTick(() => {
                    this.processTable('#reports_table')
                })
                this.loader = false


            }).catch((err) => {
                console.log("Err Plugin PlayAccountsData")
                this.loader = false
            })
        },
        processTable(id) {
            $(id).DataTable().destroy();
            var search = require("@/assets/Icons_SVG/Search.svg");
            $(id).DataTable({
                searching: true,
                info: false,
                scrollCollapse: true,
                lengthChange: true,
                ordering: false,
                // sorting: [[sortCol, "asc"]],
                initComplete: function (settings, json) {
                    $('.dataTables_scrollBody tfoot tr').css({ visibility: 'collapse' });
                    $('.dataTables_scrollBody thead tr').css({ visibility: 'collapse' });
                    $(id).wrap("<div style='overflow:auto; width:100%;position:relative;'></div>");
                },
                language: {
                    search: "", searchPlaceholder: "Search...", paginate: {
                        first: "",
                        previous: "<",
                        next: ">",
                        last: "",
                    },
                    lengthMenu: "<h5>Show entries</h5> _MENU_"
                },
            })
            $(id + "_filter > label").append(
                `<div class='search_icon'><img src='${search}' /></div>`
            );
            $(id + ' thead th:first-child').removeClass('sorting sorting_asc sorting_desc');
        },
    },
}