<template lang="pug">
    div#failedCamp-modal.fixed.top-0.animate-fade-in.left-0.right-0.hidden.h-modal.h-full.w-full(style="z-index: 99999" tabindex='-1', class='md:inset-0 md:h-full' )
        div(class="bg-white w-full h-full flex justify-center items-center")
            div(class="w-[40%] ")
                img.w-100(src="@/assets/Icons_SVG/success_loader.svg")
                div.flex.justify-center.mt-3 
                    div.cursor-pointer.w-28.text-center.pt-2.pb-2.px-3.text-white.bg-primary_brand.rounded-md.border-primary_brand(class="border-[1.5px]" @click="closeWindow") 
                        font-awesome-icon(icon="fa-solid fa-xmark" class="mr-2 ") 
                        span Close
            
        
</template>
<script>
import axios from 'axios'
export default {
    data(){
        return {
            connectAccountPopup:''
        }
    },

    mounted(){
        this.initializeModal();
        this.openModal();
    },


    methods:{
        initializeModal() {
            const $targetFailedCamp = document.getElementById('failedCamp-modal');
            const failedCampOptions = {
                placement: 'center',
                backdrop: 'dynamic',
                backdropClasses: 'bg-gray-900 bg-opacity-50 fixed inset-0 z-40',
                closable: true,
            };
            this.connectAccountPopup = new Modal($targetFailedCamp, failedCampOptions);

        },
        closeWindow(){
            // window.reload();
            localStorage.isShopify = JSON.stringify(true)
            window.location.href="https://weavr.online/connect-your-accounts";
            console.log("check the data close")
            window.close()
        },
        openModal(){
            this.connectAccountPopup.show()
            // setTimeout(function() {  window.close()}, 1000);


        },
        changeDataAfterConnectAndDisconnect() {
            const id = JSON.parse(localStorage.userData).data.client_id
            let url = "/api/users/switch_clients/" + id + "/"
            axios.post(url).then((res) => {
                axios.defaults.headers.common['token'] = res.data.data.token
                localStorage.userData = JSON.stringify(res.data)
                // localStorage.isShopify = JSON.stringify(false)
                localStorage.removeItem('selectedMenu')
                this.$nextTick(() => {
                    this.$store.dispatch('store/setUser')
                    this.$store.dispatch('store/setMenu')
                })
            }).catch(err => console.log(err))
        },
    },
}
</script>