<template>
    <div>
        <highcharts :options="chartData" id="chartId"></highcharts>
    </div>
</template>
<script>
import { Chart } from "highcharts-vue";
import Highcharts from "highcharts";
import hcHeatmap from "highcharts/modules/heatmap";
hcHeatmap(Highcharts);

export default {
    name: 'heatMapcomponents',
    props: ['data'],

    components: {
        highcharts: Chart,
    },
    data() {
        return {
            chartData: '',
            chartDataTemp: ''
        };
    },
    // watch: {
    //     data: {
    //     handler() {
    //         this.plotChart();
    //     },
    //         deep: true,
    //     }
    // },
    created() {
        this.plotChart();
    },

    methods: {
        formatNumber(num) {
            if (isNaN(num)) return num
            let num1 = Number(num).toFixed(2);
            let num2 = Number(num1).toLocaleString();
            return num2
        },

        plotChart() {
            let self = this
            this.chartData =
            {
                chart: {
                    type: "heatmap",
                    marginTop: 20,
                    // marginBottom: 80,
                    plotBorderWidth: 0
                },

                title: {
                    text: ""
                },

                xAxis: {
                    categories: this.data.xAxis,
                    title:null,
                    gridLineWidth:0,
                    lineWidth:0
                },

                yAxis: {
                    categories: this.data.yAxis,
                    title: null,
                    gridLineWidth:0
                },

                colorAxis: {
                    min: 0,
                    minColor: "#f5f5f5",
                    maxColor: '#11865B'
                },
                credits: {
                    enabled: false
                },
                legend: {
                    align: "right",
                    layout: "vertical",
                    margin: 0,
                    verticalAlign: "top",
                    y: 30,
                    symbolHeight: 280
                },

                tooltip: {
                    formatter: function () {
                        return (
                            "<b>" +
                            this.series.xAxis.categories[this.point.x] +
                            "</b> revenue<br><b>" +
                                self.formatNumber(this.point.value) +
                            "</b> on<br><b>" +
                            this.series.yAxis.categories[this.point.y] +
                            "</b>"
                        );
                    },
                    shared: true,
                    borderWidth: 0,
                    shadow: {
                        color: "#d6d6d6",
                        opacity: 0.1,
                        width: 10,
                    },
                    borderRadius: 6,
                    backgroundColor: "#ffffff",
                },

                series: this.data.seriesData
            }
            // chart: {
            //     type: 'heatmap',
            //     plotBorderWidth: 1
            // },


            // title: {
            //     text: '',
            //     style: {
            //         fontSize: '1em'
            //     }
            // },

            // xAxis: {
            //     categories: this.data.xAxis,
            //     title: 'Time'
            // },

            // yAxis: {
            //     categories: this.data.yAxis,
            //     title: 'Date',
            //     reversed: true
            // },

            // credits: {
            //     enabled: false
            // },

            // accessibility: {
            //     point: {
            //         descriptionFormat: '{series.xAxis.categories.(x)} sales ' +
            //             '{series.yAxis.categories.(y)}, {value}.'
            //     }
            // },

            // // colorAxis: {
            // //     min: 0,
            // //     minColor: '#FFFFFF',
            // //     maxColor: '#FF7209'
            // // },

            // legend: {
            //     align: 'right',
            //     layout: 'vertical',
            //     marginLeft: 20,
            //     verticalAlign: 'top',
            //     y: 25,
            //     symbolHeight: 280
            // },

            // tooltip: {
            //     userHTML: true,
            //     // formatter: function (tooltip) {
            //     //     console.log("check the tooltip data",tooltip)
            //     //     return point.value + ' : <b>' + point.x + '</b>';
            //     // },
            //     // formatter() {
            //     //     if(this.point){
            //     //     console.log("check the point data", this.point)
            //     //     let mytooltip =''
            //     //     mytooltip = this.point.name + ": ";
            //     //     mytooltip += formatNumber(this.point.total)+' ('+this.point.percentage+'%)'
            //     //     return mytooltip;
            //     //     }             
            //     // },
            //     format:
            //         '<b>{point.value}</b> {series.name} on <br>' +
            //         '<b>{series.yAxis.categories.(point.y)} at {series.xAxis.categories.(point.x)}</b>',
            //     shared: true,
            //     borderWidth: 0,
            //     shadow: {
            //         color: "#d6d6d6",
            //         opacity: 0.1,
            //         width: 10,
            //     },
            //     borderRadius: 6,
            //     backgroundColor: "#ffffff",
            // },

            // series: this.data.seriesData,

            // responsive: {
            //     rules: [{
            //         condition: {
            //             maxWidth: 500
            //         },
            //         chartOptions: {
            //             yAxis: {
            //                 labels: {
            //                     format: '{substr value 0 1}'
            //                 }
            //             }
            //         }
            //     }]
            // }

        }

    }
}
</script>
<style></style>
