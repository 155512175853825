import axios from 'axios';
import Loader from '@/components/Loader/Loader.vue';
import lineChart from '@/components/charts/lineChart.vue'
import dualAxisChart from '@/components/charts/dualAxisChart.vue'

export default {
    name: "SearchVolume",
    components: {
        Loader, lineChart,dualAxisChart
    },
    data() {
        return {
            loader: false,
            tableLoader:false,
            dateRange: {
                startDate:  moment().subtract(6,"months").format("YYYY-MM-DD"),
                endDate: moment().format("YYYY-MM-DD")
            },
            maxDate: new Date(),
            minDate: new Date(moment().subtract(1, "month")),
            intervalsList: [
                {
                    key: 'month',
                    label: 'Month'
                },
                {
                    key: 'year',
                    label: 'Year'
                },
            ],
            selectedTopTenKeywordInterval:{
                key: 'month',
                label: 'Month'
            },
            metrics : [],              
            viewAllKeywordData:'',
            viewSearchVolumeGraphData:'',
            tableHeader:[],
            tableData:[],
            selectedBrand: this.$route.params.brandName,
            viewTopKeywordGraphData:'',
            processedTopKeywordData:''
        }
    },

    mounted() {
        // this.getSavedCreatives();
        this.checkDateRange();
    },

    methods: {
        checkDateRange(){
            if(this.$store.state.filter.SVAPIDateRangeDate){
                this.setDateRange();
            }
            else{
                this.getDateRange();  
            }
        },

        getDateRange(){
            this.viewAllKeywordData = '';
            const url ="/api/v1/searchvolume/enddate"
            const payload={}
            axios.post(url,payload).then(res => {
                this.$store.dispatch('filter/setSVDateAPIDate',res.data)
                this.setDateRange(res.data);
                this.loader = false;
            }).catch(err => {
                this.loader = false;
                console.log("Error in getting search volume reports", err)
            })
        },

        applyFilter() {
            this.dateRange.startDate = moment(this.dateRange.startDate).format("YYYY-MM-DD")
            this.dateRange.endDate = moment(this.dateRange.endDate).format("YYYY-MM-DD")
            this.$store.dispatch('filter/setSelectedDate',this.dateRange)
            this.getAllKeywordTableData();
            this.getSearchVolumeTopTenKeywordGraphData();
        },

        setDateRange(){
            this.maxDate = new Date(this.$store.state.filter.SVAPIDateRangeDate.end_date);
            this.minDate = new Date(this.$store.state.filter.SVAPIDateRangeDate.start_date);
            this.dateRange.startDate = this.$store.state.filter.SVSelectedDateRange.startDate
            this.dateRange.endDate = this.$store.state.filter.SVSelectedDateRange.endDate, 
            this.getAllKeywordTableData();
            this.getSearchVolumeTopTenKeywordGraphData();
        },

        setTopKeywordInterval(selectedInterval){
            this.selectedTopTenKeywordInterval= selectedInterval
            this.getSearchVolumeTopTenKeywordGraphData();
        },

        getAllKeywordTableData(){
            this.tableLoader = true;
            this.viewAllKeywordData = '';
            $('#reports_table').DataTable().destroy();
            const url = "/api/v1/searchvolume/table";
            const payload={
                "brand": this.selectedBrand,
                "start_date": this.dateRange.startDate,
                "end_date": this.dateRange.endDate    
            }
            axios.post(url,payload).then(res => {
                this.viewAllKeywordData = res.data;
                this.$nextTick(() => {
                    this.processTable('#reports_table')
                })
                this.tableLoader = false;
            }).catch(err => {
                this.tableLoader = false;
                console.log("Error in getting search volume reports", err)
            })
        },

        getSearchVolumeTopTenKeywordGraphData(){
            this.loader = true;
            this.viewSearchVolumeGraphData = ''
            const url = "/api/v1/searchvolume/bargraph"
            const payload = {
                start_date: this.dateRange.startDate,
                end_date: this.dateRange.endDate,
                interval: this.selectedTopTenKeywordInterval.key,
                brand: this.selectedBrand,
            }

            axios.post(url, payload).then(res => {
                this.loader = false;
                this.viewTopKeywordGraphData = res.data.data
                this.processTopKeywordGraphData();
            }).catch(err => {
                this.loader = false;
                console.log("Error in getting saved reports", err)
            })

        },

        processTopKeywordGraphData(){
            this.processedTopKeywordData={
                xAxis:[],
                disableYLabel:false,
                legend: true,
                seriesData:[{
                    name:'',
                    data:[]
                }]
            }

            if(this.viewTopKeywordGraphData){
                this.viewTopKeywordGraphData.forEach((data) => {
                    this.processedTopKeywordData.xAxis.push(data.key);
                    this.processedTopKeywordData.seriesData[0].data.push(Number(data.search_volume.replaceAll(",","")));
                })
            }
        },

        processTable(id) {
            $(id).DataTable().destroy();
            var search = require("@/assets/Icons_SVG/Search.svg");
            $(id).DataTable({
                searching: true,
                info: false,
                scrollCollapse: true,
                lengthChange: true,
                ordering:true,
                sorting: true,
                order:[],
                initComplete: function (settings, json) {
                    $('.dataTables_scrollBody tfoot tr').css({ visibility: 'collapse' });
                    $('.dataTables_scrollBody thead tr').css({ visibility: 'collapse' });
                    $(id).wrap("<div style='overflow:auto; width:100%;position:relative;'></div>");
                },
                language: {
                    search: "", searchPlaceholder: "Search...", paginate: {
                        first: "",
                        previous: "<",
                        next: ">",
                        last: "",
                    },
                    lengthMenu: "<h5>Show entries</h5> _MENU_"
                },
            })
            $(id + "_filter > label").append(
                `<div class='search_icon'><img src='${search}' /></div>`
            );
            $(id + ' thead th:first-child').removeClass('sorting sorting_asc sorting_desc');
        },

        abbreviateNumber(value) {
            return Intl.NumberFormat('en-US', {
                notation: "compact",
                maximumFractionDigits: 2
            }).format(value);
        },

        formatNumber(num) {
            if (isNaN(num)) { return num }
            let num1 = Number(num).toFixed(2);
            let num2 = Number(num1).toLocaleString();
            return num2
        }
    }
}